import { FieldErrors, GqlApiError } from 'graphql/types';
import { useCallback, useEffect } from 'react';
import useMutationWithAlert from './graphql/useMutationWithAlert';

interface ReleaseLinkCreateInput {
  releaseId: string | number;
  artistId?: string | number;
  labelId?: string | number;
}

type ReleaseLinkCreateResponse = {
  releaseLinkCreate: {
    errors: FieldErrors;
    errorDetails: GqlApiError[];
    link: {
      uid: string;
      url: string;
    };
  };
};

const createReleaseLinkMutation = `
  mutation ReleaseLinkCreate($releaseId: ID!) {
    releaseLinkCreate(input: { releaseId: $releaseId }) {
      errors
      errorDetails {
        message
        path
      }
      link {
        uid
        url
      }
    }
  }
`;

/**
 * Hook that provides the method for generating a release link. Alternatively, you can pass in a releaseId
 * to automatically generate a link.
 */

function useReleaseLinksSharer(fetchReleaseId?: string) {
  const [{ fetching, data: createReleaseLinkData }, createReleaseLink] =
    useMutationWithAlert<ReleaseLinkCreateResponse, ReleaseLinkCreateInput>(
      createReleaseLinkMutation
    );

  const generateReleaseLink = useCallback(async (releaseId: string | number) => {
    const { data } = await createReleaseLink({ releaseId });
    return formatReleaseLinkResponse(data);
  }, []);

  useEffect(() => {
    if (fetchReleaseId) {
      void generateReleaseLink(fetchReleaseId);
    }
  }, [fetchReleaseId]);

  const { url, target } = formatReleaseLinkResponse(createReleaseLinkData);
  return { generateReleaseLink, fetching, url, target };
}

function formatReleaseLinkResponse(createReleaseLinkData?: ReleaseLinkCreateResponse) {
  const { uid = '', url } = createReleaseLinkData?.releaseLinkCreate?.link || {};
  return { url, target: `ReleaseLink:${uid}` };
}

export default useReleaseLinksSharer;
