const LIGHT_GRAY = '#DDDDDD';
export const COLORS = {
  PRIMARY: '#e26014',
  SECONDARY: '#F2F2F2',
  DANGER: '#e33939',
  GRAY_SUPER_DARK: '#141211',
  GRAY_DARK: '#4D4D4D',
  GRAY_MEDIUM: '#7D7D7D',
  GRAY_MEDIUM_LIGHT: '#B1B1B1',
  GRAY_LIGHT: LIGHT_GRAY,
  GRAY_LIGHTEST: '#F0F1F2',
  GRAY_SUPER_LIGHT: '#F7F8F9',
  WHITE: '#FFFFFF',
  BEATPORT_GREEN: '#01FF95',
  SPOTIFY_GREEN: '#1ED760',
  SUCCESS: '#43A047',
  BORDER: LIGHT_GRAY,
  WARNING: '#edb95e'
};

export const SMALL_BREAK_POINT = 576;
export const MEDIUM_BREAK_POINT = 768;
export const LARGE_BREAK_POINT = 992;
export const EXTRA_LARGE_BREAK_POINT = 1200;

export const MAX_WIDTH = 1650;

export const BREAKPOINTS = {
  SM: SMALL_BREAK_POINT,
  MD: MEDIUM_BREAK_POINT,
  LG: LARGE_BREAK_POINT,
  XL: EXTRA_LARGE_BREAK_POINT,

  SMALL: SMALL_BREAK_POINT,
  MEDIUM: MEDIUM_BREAK_POINT,
  LARGE: LARGE_BREAK_POINT,
  EXTRA_LARGE: EXTRA_LARGE_BREAK_POINT
};

export const FONT_SIZES = {
  XSMALL: '1.2rem',
  SMALL: '1.4rem',
  MEDIUM: '1.8rem',
  LARGE: '2.4rem',
  XLARGE: '3.6rem',
  XXLARGE: '3.8rem',
  JUMBO: '6rem'
};

export const Z_INDEX = {
  MODAL_DESKTOP: 1050,
  ALERTS: 1051, // should overlay the desktop modal
  // TODO: Header needs to be refactored so that the hamburger menu can have a separate z-index from the header
  HEADER: 1052, // should overlay the desktop modal. Alerts are rendered below the header, so we don't need
  // to overlay them
  MODAL_MOBILE: 1053, // modal on mobile should overlay the header, but not the alerts
  ALERTS_MOBILE: 1054 // should overlay modals and the header. We overlay the header because the hamburger menu
  // is part of the header should overlay the alert
} as const;

export const BOX_SHADOW =
  '0 0 20px -5px rgba(0, 0, 0, 0.08), 0 0 3px rgba(0, 0, 0, 0.12)';
export const BOX_SHADOW_HOVER = '0 0 30px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.1)';
