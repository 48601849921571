import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { COLORS } from 'config/constants';

import Flex from 'components/Flex';
import Chevron from 'components/Icons/Chevron';
import { CONTAINER_PADDING } from 'components/Layout/Container';
import Portal from 'components/Portal';
import TextHeader from 'components/TextHeader';

const OverlayHeaderHeight = '4rem';

const OverlayPageContainer = styled.div`
  background: ${COLORS.GRAY_SUPER_LIGHT};
  min-height: 100vh;
`;

const OverlayPageContent = styled.div`
  padding: ${CONTAINER_PADDING.SMALL}rem;
`;

const OverlayPageTitle = styled.div`
  border-bottom: solid 1px #ccc;
  height: ${OverlayHeaderHeight};
  display: flex;
  position: relative;
`;

const BackLink = styled(Link)`
  bottom: 0;
  left: 0;
  position: absolute;
  padding: 0 1rem;
  top: 0;
`;

const OVERLAY_ID = 'full-overlay';
const APP_CONTENT_ID = 'App-content';

/**
 * [OverlayPage] - Full screen page that is rendered from the header to the footer, regardless if
 * renderered within a nested layout. Useful for rendering mobile screens in a tabbed navigation layout
 */

interface OverlayPageProps {
  children: React.ReactNode;
  title?: string;
  enabled?: boolean;
  backPath?: string;
  backText?: string;
}

const OverlayPage = ({
  children,
  title,
  enabled = true,
  backPath,
  backText
}: OverlayPageProps) => {
  // // when an overlay page is rendered, we want to hide the App-content as not to appear as scrollable content
  // // beneath the overlay page content
  useEffect(() => {
    const overlayNode = document.getElementById(OVERLAY_ID);
    if (overlayNode && overlayNode.childElementCount > 0 && enabled) {
      const appContentNode = document.getElementById(APP_CONTENT_ID);
      appContentNode?.style && (appContentNode.style.display = 'none');
    }

    return () => {
      // TODO: Might need to modify this for multiple overlays, but saving that for another day
      if (overlayNode && (overlayNode.childElementCount === 0 || !enabled)) {
        const appContentNode = document.getElementById(APP_CONTENT_ID);
        if (appContentNode) appContentNode.style.display = 'block';
      }
    };
  }, [enabled]);

  if (!enabled) return <>{children}</>;

  return (
    <Portal domNode={document.getElementById(OVERLAY_ID)}>
      <OverlayPageContainer>
        {title && (
          <OverlayPageTitle>
            {backPath && (
              <BackLink to={backPath}>
                <Flex align="center" style={{ height: '100%' }}>
                  <Chevron direction="left" color="currentColor" />
                  {backText || 'Back'}
                </Flex>
              </BackLink>
            )}

            <Flex justify="center" align="center">
              <TextHeader type={TextHeader.TYPES.MEDIUM} className="mb-0">
                {title}
              </TextHeader>
            </Flex>
          </OverlayPageTitle>
        )}
        <OverlayPageContent>{children}</OverlayPageContent>
      </OverlayPageContainer>
    </Portal>
  );
};

export default OverlayPage;
