import React, { useEffect } from 'react';
import styled from 'styled-components';

import { hideIntercomLauncher, showIntercomLauncher } from 'config/intercom';
import type { DiscoveryModeTracksFieldsFragment } from 'gql/graphql';
import {
  MobileDiscoveryModeCellMeta,
  MobileTrackCellData,
  TrackScheduleButton
} from '../Tables/TableComponents';

import { COLORS } from '@protonradio/proton-ui';
import { ActionMenu, Button } from '@protonradio/proton-ui';

import { useDiscoveryMode } from '../DiscoveryModeContext';
import { getFormattedSchedulingEndDate } from './helpers';
import TextHeader from 'components/TextHeader';
import { isEntityArtist } from '../helpers';

const MobileTrackCellWrapper = styled.div<{ $isTop: boolean }>`
  background-color: ${COLORS.GRAYSCALE.GRAY_LIGHTEST};
  border-radius: 1rem;
  border-top-left-radius: ${({ $isTop }) => ($isTop ? '0' : '1rem')};
  border-top-right-radius: ${({ $isTop }) => ($isTop ? '0' : '1rem')};
  margin-bottom: 2rem;
`;

const Divider = styled.div`
  height: 1px;
  width: 96%;
  background-color: ${COLORS.GRAYSCALE.GRAY_LIGHT};
  margin: 0 auto;
`;

const ActionMenuButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  padding-top: 1rem;

  > button {
    padding: 1.4rem 4rem;
  }
`;

interface SchedulingActionMenuProps {
  isOpen: boolean;
  onClose: () => void;
  trackData: DiscoveryModeTracksFieldsFragment;
}

const SchedulingActionMenu = ({
  isOpen,
  onClose,
  trackData
}: SchedulingActionMenuProps) => {
  const {
    scheduledQueue: { count, toggleTrack },
    discoveryMode,
    selectedEntity
  } = useDiscoveryMode();

  const isArtist = isEntityArtist(selectedEntity);
  const { schedulingEndsAt, title } = discoveryMode?.upcomingCampaign || {};
  const formattedDate = getFormattedSchedulingEndDate(schedulingEndsAt);

  useEffect(() => {
    if (isOpen) {
      hideIntercomLauncher();
    }
    return () => {
      showIntercomLauncher();
    };
  }, [isOpen]);

  return (
    <ActionMenu
      isOpen={isOpen}
      onClose={onClose}
      closeOnNavigation={false}
      showCancel={false}
    >
      <TextHeader type={TextHeader.TYPES.LARGE}>
        {3 - count} {3 - count === 1 ? 'track' : 'tracks'} remaining for {title}
      </TextHeader>

      <div className="p-4 mt-2">
        <MobileTrackCellWrapper $isTop={Boolean(trackData?.spotifyRecommend)}>
          <div style={{ padding: '1.4rem' }}>
            <MobileTrackCellData trackCampaignData={trackData} />
            <MobileDiscoveryModeCellMeta trackCampaignData={trackData} />
          </div>

          <Divider />

          <i
            style={{
              fontSize: '1.2rem',
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '1rem'
            }}
          >
            Schedule by {formattedDate}
          </i>

          <ActionMenuButtonGroup>
            <Button variant="secondary" onPress={onClose}>
              Back
            </Button>
            <TrackScheduleButton
              trackCampaignData={trackData}
              onPress={() => {
                toggleTrack({
                  campaignId: discoveryMode?.upcomingCampaign?.id || '',
                  trackId: trackData.track.id,
                  enabled: trackData.enabled,
                  ...(isArtist
                    ? { artistId: selectedEntity.id }
                    : { labelId: selectedEntity?.id })
                });
              }}
            />
          </ActionMenuButtonGroup>
        </MobileTrackCellWrapper>
      </div>
    </ActionMenu>
  );
};

export default SchedulingActionMenu;
