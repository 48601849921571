import React from 'react';
import styled from 'styled-components';

import { FilePickerActionMenu } from 'components/ActionMenu';
import { EllipsisButton } from 'components/Button';
import Select, { BaseOption } from 'components/Form/Inputs/Select/Select';
import MenuToggler from 'components/MenuToggler';

import useMutationWithAlert from 'hooks/graphql/useMutationWithAlert';

import {
  AUDIO_FILE_TYPES,
  BREAKPOINTS,
  DownloadFileType,
  MEDIUM_BREAK_POINT
} from 'config/constants';
import { LOSSLESS_DOWNLOADS } from 'config/features/featureFlags';

import {
  getCurrentDownloadLocationOption,
  getDownloadLocationOptions
} from 'helpers/downloadLocation';

import { canDownloadLossless, trackDownloadConfig } from 'helpers';
import useBreakpoint from 'hooks/useBreakpoint';
import useCurrentUser from 'hooks/useCurrentUser';
import { selectIsFeatureActive, selectUser } from 'redux/selectors';
import { useAppSelector } from 'hooks/redux';

const UpdateUserSettingsMutation = `
  mutation updateUserSettings ($input: UserSettingUpdateInput!) {
    userSettingUpdate (input: $input) {
      errors
      userSetting {
        enabled
        settingType
        value
      }
    }
  }
`;

const SelectWrapper = styled.div`
  padding: 2rem 2rem 0.01rem 2rem;

  > div {
    margin-bottom: 2rem;
  }
`;

const MoreWrapper = styled.div<{ $isVisible: boolean }>`
  background: #fff;
  border-radius: 0.6rem;
  cursor: pointer;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};

  @media screen and (max-width: ${MEDIUM_BREAK_POINT}px) {
    opacity: 1;
  }
`;

const FILE_TYPE_OPTIONS = Object.values(AUDIO_FILE_TYPES).map(item => ({
  label: item.LABEL,
  value: item.VALUE
}));

const PromoReactionDropdownMenu = ({
  disabled,
  isVisible: _isVisible,
  track,
  onChange
}) => {
  const user = useAppSelector(selectUser);
  const { user: currentUser } = useCurrentUser();
  const isMediumScreen = useBreakpoint(BREAKPOINTS.MD, useBreakpoint.DIRECTIONS.DOWN);

  const [{ hasErrors }, updateUserSettings] = useMutationWithAlert(
    UpdateUserSettingsMutation
  );

  const isLossless = canDownloadLossless(currentUser, { track, user });

  const trackDownloadSettings = trackDownloadConfig(currentUser, { track, user });

  const currentFileTypeOption = FILE_TYPE_OPTIONS.find(
    ({ value }) => value === trackDownloadSettings.filetype
  );

  const downloadLocationOptions = getDownloadLocationOptions(user);
  const currentLocationOption = getCurrentDownloadLocationOption(currentUser, user) || {
    value: '',
    label: ''
  };

  const handleSettingsUpdate = (settingType: string, value: string) => {
    // Optimistically update the track download settings
    if (settingType === 'DOWNLOAD_FORMAT') {
      onChange && onChange(value as DownloadFileType);
    }

    updateUserSettings({
      input: {
        userId: currentUser.id,
        settingType,
        value,
        enabled: true
      }
    });

    if (hasErrors && settingType === 'DOWNLOAD_FORMAT') {
      // Rollback the optimistically updated track download settings
      onChange && onChange(trackDownloadSettings.filetype);
    }
  };

  return (
    <MenuToggler
      forceActionMenuDisplay={isMediumScreen}
      renderToggle={({ isOpen, isVisible, toggle }) => {
        const isActive = isOpen && isVisible;

        return (
          <MoreWrapper $isVisible={_isVisible || isActive}>
            <EllipsisButton
              isActive={isActive}
              onClick={toggle}
              disabled={disabled}
              data-testid="PromoReactionDropdownMenu--Picker"
            />
          </MoreWrapper>
        );
      }}
      renderPopoverContent={({ close }) => (
        <SelectWrapper data-testid="track-settings-popover">
          <Select
            value={currentFileTypeOption}
            label="File Type"
            options={FILE_TYPE_OPTIONS}
            disabled={!isLossless}
            onChange={(e: BaseOption) => {
              handleSettingsUpdate('DOWNLOAD_FORMAT', e.value.toString());
              close();
            }}
            formik={false}
          />
          <Select
            value={currentLocationOption as BaseOption}
            label="Download Location"
            options={downloadLocationOptions as BaseOption[]}
            onChange={(e: BaseOption) => {
              handleSettingsUpdate('DOWNLOAD_LOCATION', e.value.toString());
              close();
            }}
            formik={false}
            style={{ minWidth: '28rem' }}
          />
        </SelectWrapper>
      )}
      renderActionMenu={({ isOpen, close }) => (
        <FilePickerActionMenu
          isOpen={isOpen}
          close={close}
          closeOnNavigation
          handleSettingsUpdate={handleSettingsUpdate}
          downloadLocationOptions={downloadLocationOptions}
          currentLocationOption={currentLocationOption}
          track={track}
        />
      )}
    />
  );
};

PromoReactionDropdownMenu.SELECTORS = {
  PICKER: '[data-testid=PromoReactionDropdownMenu--Picker]'
};

export default PromoReactionDropdownMenu;
