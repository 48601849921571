import { useCallback, useEffect, useMemo, useState } from 'react';
import useMutationWithAlert from 'hooks/graphql/useMutationWithAlert';
import { useAppDispatch } from 'hooks/redux';

import { showAlert } from 'redux/actions/ui';
import { isTrackLoaded, transformScheduledTrack } from './helpers';

import { EnableDisableTrackMutation } from 'graphql/mutations/discoveryMode';

import type { DiscoveryModeEnableDisableTrackInput } from 'gql/graphql';
import type { CombinedError } from 'urql';

type ScheduledTrackState = 'adding' | 'removing' | boolean;
export type ScheduledTrackData = {
  id: string;
  img: string;
  title: string;
  artist: string;
  loading: ScheduledTrackState;
  error?: CombinedError | null;
};
export type ScheduledTrackLoading = {
  id: string;
  loading: ScheduledTrackState;
  error?: CombinedError | null;
};
export type ScheduledTrack = ScheduledTrackData | ScheduledTrackLoading;
export type ScheduledTracksCart = ScheduledTrack[] & { length: 0 | 1 | 2 | 3 };

const useScheduledQueue = (scheduledTrackData: ScheduledTrackData[]) => {
  useEffect(
    () => setQueue(scheduledTrackData as ScheduledTracksCart),
    [
      // Only depend on the relevant data from enabled tracks
      scheduledTrackData.map(t => t.id).join(',')
    ]
  );

  const [queue, setQueue] = useState<ScheduledTracksCart>([]);
  const [, enableDisableTrack] = useMutationWithAlert(EnableDisableTrackMutation);
  const dispatch = useAppDispatch();

  const addToQueue = useCallback((pendingTrack: ScheduledTrackLoading) => {
    setQueue(prevTracks => [...prevTracks, pendingTrack] as ScheduledTracksCart);
  }, []);

  const removeFromQueue = useCallback((trackId: string) => {
    setQueue(
      prevTracks =>
        prevTracks.filter(
          t => isTrackLoaded(t) && t.id !== trackId
        ) as ScheduledTracksCart
    );
  }, []);

  const updateInQueue = useCallback(
    (oldTrack: ScheduledTrack, newTrack: ScheduledTrack) => {
      setQueue(
        prevTracks =>
          prevTracks.map(t =>
            t.id === oldTrack.id ? { ...t, ...newTrack } : t
          ) as ScheduledTracksCart
      );
    },
    []
  );

  const addTrack = useCallback(
    async (input: DiscoveryModeEnableDisableTrackInput) => {
      const pendingTrack: ScheduledTrackLoading = {
        loading: 'adding',
        error: null,
        id: input.trackId
      };

      const count = queue.filter(t => isTrackLoaded(t)).length;

      if (count >= 3) {
        dispatch(
          showAlert({
            error:
              'You can only submit up to three tracks for Spotify Discovery Mode per month'
          })
        );
        return;
      }

      addToQueue(pendingTrack);

      const { data, error, hasErrors } = await enableDisableTrack({
        input: { ...input, enabled: true }
      });

      if (hasErrors || error) {
        removeFromQueue(input.trackId);
        return;
      }

      const track = data?.discoveryModeEnableDisableTrack?.discoveryModeTrack?.track;
      if (track) {
        const scheduledTrack = transformScheduledTrack(track);
        updateInQueue(pendingTrack, scheduledTrack);
      }
    },
    [queue, addToQueue, updateInQueue, enableDisableTrack, removeFromQueue]
  );

  const removeTrack = useCallback(
    async (input: DiscoveryModeEnableDisableTrackInput) => {
      const trackToRemove = queue.find(t => isTrackLoaded(t) && t.id === input.trackId);
      if (!trackToRemove) {
        dispatch(showAlert('Error removing track, try again later'));
        return;
      }

      updateInQueue(trackToRemove, {
        ...trackToRemove,
        loading: 'removing',
        error: null
      });

      const { data, error, hasErrors } = await enableDisableTrack({
        input: { ...input, enabled: false }
      });

      if (hasErrors || error) {
        updateInQueue(trackToRemove, { ...trackToRemove, loading: false, error });
        return;
      }

      if (data) {
        removeFromQueue(input.trackId);
      }
    },
    [removeFromQueue, updateInQueue, enableDisableTrack, dispatch, queue]
  );

  const toggleTrack = useCallback(
    async (input: DiscoveryModeEnableDisableTrackInput) => {
      if (input.enabled) {
        return removeTrack(input);
      } else {
        return addTrack(input);
      }
    },
    [removeTrack, addTrack]
  );

  const count = useMemo(() => queue.filter(t => isTrackLoaded(t)).length, [queue]);

  return { queue, count, toggleTrack };
};

export default useScheduledQueue;
