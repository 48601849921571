import React from 'react';
import styled from 'styled-components';

import Avatar, {
  ArtistAvatar,
  LabelAvatar,
  ReleaseAvatar,
  ShowAvatar
} from 'components/Avatar';
import BlurredBackground from 'components/BlurredBackground';
import Flex from 'components/Flex';
import Container from 'components/Layout/Container';
import Grid from 'components/Layout/Grid';
import Page from 'components/Layout/Page';
import TextHeader from 'components/TextHeader';
import { BREAKPOINTS, ENTITY_TYPES } from 'config/constants';
import type { PageEntity } from 'graphql/types';
import useBreakpoint from 'hooks/useBreakpoint';
import ImageUploadBanner from './ImageUploadBanner';
import {
  Actions,
  Content,
  StyledContentBody,
  StyledNavTabWrapper,
  StyledTabbedHeader
} from './styles';
import { ThemeProvider, THEMES } from '@protonradio/proton-ui';

const AvatarWrapper = styled.div<{ $isShow?: boolean }>`
  margin-bottom: -3rem;
  position: relative;
  overflow: hidden;
  z-index: 1;

  @media screen and (max-width: ${BREAKPOINTS.LG}px) {
    margin: 0 0 1.5rem 0;
    width: 100%;
  }

  @media screen and (max-width: ${BREAKPOINTS.MD}px) {
    margin: 0 auto -1.5rem auto;
    max-width: ${({ $isShow }) => ($isShow ? '65%' : '20rem')};
  }

  @media screen and (max-width: ${BREAKPOINTS.SM}px) {
    ${({ $isShow }) => $isShow && 'max-width: 80%'};
  }
`;

// TODO - Replace with common EntityAvatar or add renderAvatar renderprop
// to TabbedHeaderLayout
const getAvatar = ({
  imageUrl,
  entityType,
  loading = false
}: {
  imageUrl: string;
  entityType: PageEntity;
  loading?: boolean;
}) => {
  const commonAvatarProps = {
    imageUrl,
    size: Avatar.SIZES.FULL,
    loading
  };

  switch (entityType) {
    case ENTITY_TYPES.LABEL:
      return <LabelAvatar altTag="Label Art" {...commonAvatarProps} />;

    case ENTITY_TYPES.ARTIST:
      return <ArtistAvatar altTag="Artist Image" {...commonAvatarProps} />;

    case ENTITY_TYPES.SHOW:
      return <ShowAvatar altTag="Show Image" {...commonAvatarProps} />;

    case ENTITY_TYPES.RELEASE:
      return <ReleaseAvatar altTag="Show Image" {...commonAvatarProps} />;

    default:
      return null;
  }
};

export interface TabbedHeaderProps {
  collapse?: boolean;
  imageUrl?: string;
  imageWidth?: string | number;
  customBackground?: React.ReactNode;
  entityType?: PageEntity;
  onImageUpload?: () => void;
  onImageDelete?: () => void;
  overlapImage?: boolean;
  renderHeaderActions?: () => React.ReactNode;
  renderHeaderContent?: () => React.ReactNode;
  renderTabs?: () => React.ReactNode;
  renderNotification?: () => React.ReactNode;
  loading?: boolean;
  seo?: {
    description: string;
    title: string;
    image?: string;
    section: string;
  };
  subtitle?: React.ReactNode;
  title?: React.ReactNode | null;
  titleLabel?: string;
}

const TabbedHeader = ({
  collapse,
  imageUrl,
  imageWidth,
  customBackground,
  onImageUpload,
  onImageDelete,
  entityType,
  renderHeaderActions = () => null,
  renderHeaderContent = () => null,
  renderTabs = () => null,
  renderNotification = () => null,
  seo,
  subtitle,
  title,
  titleLabel
}: TabbedHeaderProps) => {
  const isLargeScreen = useBreakpoint(BREAKPOINTS.LG, 'up');
  const isSmallScreen = useBreakpoint(BREAKPOINTS.MD, 'down');

  const StyledBodyContent = (
    <StyledContentBody $collapse={collapse}>
      <Flex column grow={1}>
        <Page.Header
          color={TextHeader.COLORS.LIGHT}
          category={titleLabel}
          title={title}
          subtitle={subtitle}
          seo={seo}
          center={isSmallScreen}
          as="div"
        />

        {renderHeaderContent()}
      </Flex>

      {renderTabs() && <StyledNavTabWrapper>{renderTabs()}</StyledNavTabWrapper>}
    </StyledContentBody>
  );

  return (
    <ThemeProvider theme={THEMES.DARK}>
      <StyledTabbedHeader data-testid="TabbedHeaderStatic">
        {customBackground}

        <Content>
          <Container size={Container.SIZES.EXTRA_LARGE}>
            {imageUrl ? (
              <Grid
                columns={{ default: `${imageWidth} 1fr`, lg: '23.5rem 1fr', md: '1fr' }}
                gap={isLargeScreen ? '3rem' : '2.2rem'}
              >
                <AvatarWrapper $isShow={entityType === ENTITY_TYPES.SHOW}>
                  {entityType && getAvatar({ imageUrl, entityType: entityType })}

                  {onImageUpload && (
                    <ImageUploadBanner
                      onImageDelete={onImageDelete}
                      onImageUpload={onImageUpload}
                      entityType={entityType}
                    />
                  )}
                </AvatarWrapper>

                {StyledBodyContent}
              </Grid>
            ) : (
              <Flex>{StyledBodyContent}</Flex>
            )}
          </Container>
        </Content>

        <Actions>{renderHeaderActions()}</Actions>

        {!customBackground && (
          <BlurredBackground imageSrc={imageUrl} className="TabbedHeader__canvas" />
        )}

        {renderNotification()}
      </StyledTabbedHeader>
    </ThemeProvider>
  );
};

interface TabbedHeaderTombstoneProps {
  imageUrl?: string;
  imageWidth: string | number;
  entityType?: PageEntity;
  seo: {
    description: string;
    title: string;
    image?: string;
    section: string;
  };
}

const NavTabPlaceholder = styled.div`
  @media screen and (max-width: ${BREAKPOINTS.SM}px) {
    height: 35px;
  }
`;

export const TabbedHeaderTombstone = ({
  imageUrl,
  imageWidth,
  entityType,
  seo
}: TabbedHeaderTombstoneProps) => {
  const isLargeScreen = useBreakpoint(BREAKPOINTS.LG, 'up');

  return (
    <StyledTabbedHeader>
      <Content>
        <Container size={Container.SIZES.EXTRA_LARGE}>
          <Grid
            columns={{ default: `${imageWidth} 1fr`, lg: '23.5rem 1fr', md: '1fr' }}
            gap={isLargeScreen ? '3rem' : '2.2rem'}
          >
            <AvatarWrapper>
              {entityType &&
                getAvatar({ imageUrl: '', entityType: entityType, loading: true })}
            </AvatarWrapper>

            <StyledContentBody>
              <Page.Header seo={seo} loading as="div" />
            </StyledContentBody>
          </Grid>

          <NavTabPlaceholder />
        </Container>
      </Content>
      <BlurredBackground imageSrc={imageUrl} className="TabbedHeader__canvas" />
    </StyledTabbedHeader>
  );
};

export default TabbedHeader;
