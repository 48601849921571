import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { isProduction } from 'config/constants';

/**
 * SEO Helper Component
 * Use: Include in various routes to dynamically update the meta tags within the page <head>.
 *      Current caveat is that we need to include some opengraph meta tags in the index.html
 *      until we add SSR as Facebook/Twitter wont always parse javascript, and those wont be
 *      replaced by React Helmet.
 */

type Props = {
  // Title of the page to be used in <title> tag and related opengraph tags.
  title?: string;
  // Description of the page that will be used in the meta description tag and related opengraph tags.
  description?: string;
  // Image URL to be used in shared links on social media.
  image?: string;
  // Name of the general section of the web app. This appears in-between the page title and "Proton Radio" (ex: John Digweed - Mixes - Proton Radio)
  section?: string;
};

const SEO = ({ title, description, image, section }: Props) => {
  const DEFAULT_TITLE = 'Proton Radio - Streaming Electronic Music';
  const DEFAULT_DESCRIPTION =
    'Streaming the best in electronic music live and On Demand. Breaks, Minimal, Progressive, House, Techno, Electro, and more on 200+ exclusive shows.';

  const _titleEnd = section ? `${section} - Proton Radio` : 'Proton Radio';
  const _title = title ? `${title} - ${_titleEnd}` : DEFAULT_TITLE;
  const _description =
    description && typeof description === 'string'
      ? description.substring(0, 300)
      : DEFAULT_DESCRIPTION;
  const _image = image || '';

  return (
    <Helmet>
      <title>{_title}</title>
      <meta name="title" itemProp="title" content={_title} />
      <meta name="description" itemProp="description" content={_description} />
      {!isProduction && <meta name="robots" content="noindex, nofollow" />}
      <meta name="image" itemProp="image" content={_image} />
      <meta name="thumbnail" itemProp="thumbnailUrl" content={_image} />
      <meta
        name="keywords"
        content="proton radio, proton music, dj, electronic music, on demand, minimal, breaks, progressive, house, techno, electro, mixes, chart, top ten, radio, set, mp3, live, dj mixes, electronic music radio station, progressive house radio"
      />

      <meta name="og:site_name" content="Proton Radio" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={_title} />
      <meta property="og:description" content={_description} />
      <meta property="og:image" content={_image} />
      <meta
        property="og:url"
        content={`https://www.protonradio.com/${window.location.pathname}`}
      />

      <meta name="twitter:site" content="@protonradio" />
      <meta name="twitter:card" content="summary" />
      <meta property="twitter:title" content={_title} />
      <meta property="twitter:description" content={_description} />
      <meta property="twitter:image" content={_image} />
      <meta
        property="twitter:url"
        content={`https://www.protonradio.com/${window.location.pathname}`}
      />
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  section: PropTypes.string
};

export default SEO;
