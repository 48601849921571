import React, { ComponentProps, useState } from 'react';

import { DownloadIcon, PacmanIcon } from 'components/Icons';
import { default as OldIcon } from 'components/Icon';
import Button from 'components/Button';
import Flex from 'components/Flex';
import PromoReactionDropdownMenu from 'components/PromoReactionWidget/PromoReactionDropdownMenu';
import {
  DOWNLOAD_LOCATIONS,
  COLORS,
  DownloadFileType,
  DownloadLocation,
  AudioType
} from 'config/constants';
import useCurrentUser from 'hooks/useCurrentUser';
import { trackDownloadConfig } from 'helpers/tracks';
import useAudioDownloader from 'hooks/useAudioDownloader';
import { AlgoliaTrack } from 'types/algolia';
import { useAppSelector } from 'hooks/redux';
import { selectUser } from 'redux/selectors';
import EllipsisLoader from 'components/Loader/EllipsisLoader';

/**
 * [DownloadTrackButton] - Button with inline filetype picker. Downloads passed trackId when clicked using fileType selected (persists to redux store)
 * Has minimal styling and leave up to parent to do additional styling.
 */

interface DownloadArgs {
  isDownloading: boolean;
  isDisabled: boolean;
  color: string;
  downloadComplete: boolean;
}

const DownloadStatusIcon = ({
  isDownloading,
  isDisabled,
  color,
  downloadComplete
}: DownloadArgs) => {
  if (downloadComplete) {
    return <OldIcon type={OldIcon.TYPES.CHECK_MARK} color={color} width={15} />;
  } else if (isDownloading) {
    return (
      <PacmanIcon color={COLORS.GRAY_LIGHT} size={15} style={{ position: 'relative' }} />
    );
  } else {
    return (
      <DownloadIcon
        size={15}
        style={{
          top: 1,
          position: 'relative'
        }}
        color={isDisabled ? COLORS.GRAY_LIGHT : color}
      />
    );
  }
};

export type DownloadButtonActions = {
  onDownloadSuccess?: () => void;
  onDownload?: () => void;
};

interface Props extends ComponentProps<typeof Button>, DownloadButtonActions {
  track: AlgoliaTrack & { hasArchiveAccess: boolean; __audioType: AudioType };
  disabled?: boolean;
  format?: DownloadFileType;
  buttonStyles?: object;
  dropdownStyles?: object;
}

const DownloadTrackButton = ({
  track,
  disabled,
  onDownloadSuccess,
  onDownload,
  format,
  buttonStyles,
  dropdownStyles,
  ...rest
}: Props) => {
  const [isHovering, setIsHovering] = useState(false);
  const user = useAppSelector(selectUser);

  const { user: currentUser } = useCurrentUser();
  const downloadConfig = trackDownloadConfig(currentUser, { track, user, format });
  const [downloadFormat, setDownloadFormat] = useState<DownloadFileType>(
    downloadConfig.filetype
  );

  const [updatingDownloadFormat, setUpdatingDownloadFormat] = useState(false);

  // Optimistic update to filetype when download format is changed via the dropdown menu
  const onFormatChange = async (format: DownloadFileType) => {
    setUpdatingDownloadFormat(true);
    setDownloadFormat(format);
    // Show loader while updating the format
    await new Promise(resolve => setTimeout(resolve, 1000));
    setUpdatingDownloadFormat(false);
  };

  const { startDownload, isDownloading } = useAudioDownloader();
  const [downloadComplete, setDownloadComplete] = useState(false);

  const handleDownloadSuccess = () => {
    if (onDownloadSuccess) onDownloadSuccess();
    setDownloadComplete(true);
  };

  const isDisabled = disabled || isDownloading;
  const textColor =
    (isHovering || downloadComplete) && !disabled ? COLORS.PRIMARY : COLORS.GRAY_MEDIUM;

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Flex align="center" justify="flex-start">
        <DownloadStatusIcon
          color={textColor}
          isDownloading={isDownloading}
          downloadComplete={downloadComplete}
          isDisabled={isDisabled}
        />
        <Button
          onClick={() => {
            onDownload && onDownload();
            startDownload(track, {
              format: downloadFormat,
              onDownloadSuccess: handleDownloadSuccess
            });
          }}
          className="pr-1"
          color={Button.COLORS.TRANSPARENT}
          data-testid={DownloadTrackButton.TEST_IDS.BUTTON}
          disabled={isDisabled}
          style={{
            marginRight: 'auto',
            marginLeft: 'auto',
            ...buttonStyles
          }}
          {...rest}
        >
          <div
            style={{
              fontSize: 14,
              color: textColor,
              top: -1,
              position: 'relative'
            }}
          >
            {updatingDownloadFormat ? (
              <EllipsisLoader />
            ) : (
              getDownloadText({
                format: downloadFormat,
                isDownloading,
                value: downloadConfig.location,
                downloadComplete
              })
            )}
          </div>
        </Button>
        <Flex grow={0} shrink={0} style={{ marginLeft: 'auto', ...dropdownStyles }}>
          <PromoReactionDropdownMenu
            disabled={isDisabled}
            isVisible
            track={track}
            onChange={onFormatChange}
          />
        </Flex>
      </Flex>
    </div>
  );
};

interface DownloadTextArgs {
  isDownloading: boolean;
  downloadComplete: boolean;
  value: DownloadLocation;
  format: DownloadFileType;
}

const getDownloadText = ({
  isDownloading,
  downloadComplete,
  value,
  format
}: DownloadTextArgs): string => {
  const formatLabel = String(format).toUpperCase();
  if (
    value === DOWNLOAD_LOCATIONS.GOOGLE.VALUE ||
    value === DOWNLOAD_LOCATIONS.DROPBOX.VALUE
  ) {
    return isDownloading
      ? 'Saving to Cloud'
      : downloadComplete
        ? 'Saved to Cloud'
        : 'Save to Cloud';
  } else {
    return isDownloading
      ? `Downloading ${formatLabel}`
      : downloadComplete
        ? `Downloaded ${formatLabel}`
        : `Download ${formatLabel}`;
  }
};

DownloadTrackButton.TEST_IDS = {
  BUTTON: 'DownloadTrackButton'
};

export default DownloadTrackButton;
