import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Masonry } from 'masonic';

import { isDataStale } from 'helpers/data';
import usePagination from 'hooks/usePagination';

import FeedbackCard from 'components/Card/Cards/FeedbackCard';
import NoDataDisplay from 'components/NoDataDisplay';
import InfiniteScroller from 'components/InfiniteScroller';
import useBreakpoint from 'hooks/useBreakpoint';
import { SMALL_BREAK_POINT } from 'config/constants';

const ContentContainer = styled.div`
  margin-bottom: 5rem;

  // NOTE: on mobile, we reduce margin to limit how far the user has to scroll to trigger loading
  // next page of results
  @media screen and (max-width: ${SMALL_BREAK_POINT}px) {
    margin-bottom: 1rem;
  }

  /* Masonic grid container has outline when clicking on grid item by default */
  [role='grid']:focus {
    outline: none;
  }
`;

const STALE_MINUTES = 5;

const FeedbackCardRenderer = ({ data, index }) => {
  if (data.tombstone) {
    if (data.hidden && !data.invisible) return null;
    return <FeedbackCard.Tombstone index={index} invisible={data.invisible} />;
  }

  return <FeedbackCard {...data} />;
};

FeedbackCardRenderer.propTypes = {
  data: PropTypes.shape(),
  index: PropTypes.number
};

const FeedbackList = ({
  paginationReducer,
  paginationReducerId,
  fetchApi,
  noFeedbackMessage
}) => {
  const { data, meta, isFetching, lastFetched, error, fetch } = usePagination({
    normalizeReducer: 'feedback.byId',
    paginationReducer,
    paginationReducerId,
    fetchApi
  });

  const { current_page, total_pages } = meta;

  // If no data to display, fetch data.
  useEffect(() => {
    if (isFetching || error) return;
    if (current_page === 0) {
      fetch();
      return;
    }

    if (isDataStale(lastFetched, STALE_MINUTES)) fetch();
  }, [fetch, isFetching, current_page, lastFetched, error]);

  const hasMore = current_page < total_pages;

  const isSmallScreen = useBreakpoint(
    useBreakpoint.BREAKPOINTS.SMALL,
    useBreakpoint.DIRECTIONS.DOWN
  );
  const isBigScreen = useBreakpoint(
    useBreakpoint.BREAKPOINTS.LARGE,
    useBreakpoint.DIRECTIONS.UP
  );
  const isMediumScreen = !isSmallScreen && !isBigScreen;

  if (data.length === 0 && !isFetching) {
    return (
      <ContentContainer>
        <NoDataDisplay text={noFeedbackMessage || 'No feedback received yet'} />
      </ContentContainer>
    );
  }

  // Since we want the loading cards to fill in at the bottom of the masonry grid when infinite
  // scrolling, we add loading placeholders to the array of data we already have
  const loadingCardCount = isBigScreen ? 7 : 3;
  const loadingCards = [...Array(7)].map((_, index) => {
    const exceedsLoadingCardCount = index >= loadingCardCount;
    // const displayLoading = hasMore || (data.length === 0 && isFetching);
    const displayLoading = (hasMore && isFetching) || (data.length === 0 && isFetching);

    return {
      id: index,
      key: `tombstone:${index}`,
      tombstone: true,
      // Because the masonry component doesn't play nice if items are removed, we use a hidden flag instead
      hidden: exceedsLoadingCardCount || !displayLoading,
      // rather than fully remove the card on pagination loading, we reserve space for the loading cards so that
      // they are reasonably placed by masonic.  It's weird... but it works.
      // NOTE: on small screens, we don't reserve space since theuy are stacked one over the other and all the
      // extra space causes a delay in te pagination being triggered
      invisible: !isSmallScreen && !displayLoading && hasMore
    };
  });

  const cards = [...data, ...loadingCards];

  return (
    <ContentContainer data-testid={FeedbackList.TEST_IDS.FEEDBACK_LIST}>
      {/* NOTE: fetchOffsetPixels is the approximate height of a feedback card so that
          loading triggers once one feedback card from end of list */}
      <InfiniteScroller
        hasMore={hasMore}
        fetchMoreData={fetch}
        fetchOffsetPixels={isMediumScreen ? 550 : 425}
      >
        <Masonry
          items={cards}
          render={FeedbackCardRenderer}
          // Sets the minimum column width
          columnWidth={300}
          columnGutter={20}
        />
      </InfiniteScroller>
    </ContentContainer>
  );
};

FeedbackList.TEST_IDS = {
  FEEDBACK_LIST: 'FeedbackList'
};

FeedbackList.propTypes = {
  paginationReducer: PropTypes.string,
  paginationReducerId: PropTypes.string,
  fetchApi: PropTypes.func,
  noFeedbackMessage: PropTypes.string
};

export default FeedbackList;
