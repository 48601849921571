import React, { ComponentType } from 'react';
import { useLocation } from 'react-router-dom';

import Modal from 'components/Modal';
import { PacmanIcon } from 'components/Icons';

import { DiscoveryModeConfigsQuery } from 'graphql/queries/discoveryMode';

import { OnboardingStep } from './OnboardingStep';
import { OptInSuccess } from './SuccessStep';
import { SelectEntities } from './EntitySelectionStep';
import { useQueryWithAlert } from 'hooks/graphql/useQueryWithAlert';
import { StyledModal } from '../styles';
import { DiscoveryModeConfigsQueryQuery } from 'gql/graphql';
import { DiscoveryModeHistoryState } from '../types';

interface Entity {
  id?: string;
  type: 'artist' | 'label';
}

// If a user only manages one artist/label return that entity, or null
const getSingleEntity = (
  viewer: DiscoveryModeConfigsQueryQuery['viewer']
): Entity | null => {
  const artistsCount = viewer?.artists?.length ?? 0;
  const labelsCount = viewer?.managedLabels?.length ?? 0;

  if (artistsCount === 1 && labelsCount === 0) {
    return { id: viewer?.artists[0].id, type: 'artist' };
  }

  if (labelsCount === 1 && artistsCount === 0) {
    return { id: viewer?.managedLabels[0].id, type: 'label' };
  }

  return null;
};

export interface OptInProps {
  viewer: NonNullable<DiscoveryModeConfigsQueryQuery['viewer']>;
  modalLocation: string;
  entityId?: string;
  entityType?: 'artist' | 'label';
}

type OptInModalSteps = 'dm-opt-in' | 'success' | 'select';

const OptInModalSteps: Record<OptInModalSteps, ComponentType<OptInProps>> = {
  'dm-opt-in': OnboardingStep,
  success: OptInSuccess,
  select: SelectEntities
};

const OptInModal = () => {
  const [{ data, fetching }] = useQueryWithAlert({
    query: DiscoveryModeConfigsQuery
  });
  const location = useLocation<DiscoveryModeHistoryState>();

  // We want to skip the entity selection step if the user only manages one artist or label
  const singleEntity = getSingleEntity(data?.viewer);

  const currentStep = location.state?.step || 'dm-opt-in';
  const StepComponent = OptInModalSteps[currentStep];

  if (fetching || !data?.viewer) {
    return (
      <Modal>
        <StyledModal>
          <PacmanIcon />
        </StyledModal>
      </Modal>
    );
  }

  return (
    <StepComponent
      viewer={data.viewer}
      modalLocation={location.state?.modalLocation || '/discovery-mode'}
      entityId={location.state?.entityId ?? singleEntity?.id}
      entityType={location.state?.entityType ?? singleEntity?.type}
    />
  );
};

export default OptInModal;
