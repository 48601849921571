import { DiscoveryModeTracksFieldsFragment } from 'gql/graphql';
import { DiscoveryModeEntity } from '../DiscoveryModeContext';
import { formatDate } from '../helpers';
import { ScheduledTrack, ScheduledTrackData } from './useScheduledQueue';

const subtractOneMinute = (date: Date): Date => {
  date.setTime(date.getTime() - 60000);
  return date;
};

export const isTrackLoaded = (track: ScheduledTrack): track is ScheduledTrackData =>
  track.loading === false;

/**
 * Returns the last scheduling day as a string with the format: "Weekday, Month Day(st/nd/rd/th)".
 */
export const getFormattedSchedulingEndDate = (
  schedulingEndsAt?: string
): string | null => {
  if (!schedulingEndsAt) return null;

  const endsAtDate = new Date(schedulingEndsAt);
  // Check if date is invalid (NaN is returned for invalid dates)
  if (isNaN(endsAtDate.getTime())) return null;

  //since schedulingEndsAt midnight, we have to remove one minute to get the last scheduling day
  const lastSchedulingDay = subtractOneMinute(endsAtDate);
  return formatDate(lastSchedulingDay);
};

/**
 * Transforms a track into a ScheduledTrackData object.
 */
export const transformScheduledTrack = (
  track: Pick<
    DiscoveryModeTracksFieldsFragment['track'],
    'id' | 'title' | 'version' | 'originalArtists' | 'release'
  >
): ScheduledTrackData => ({
  id: track.id,
  img: track.release?.coverArt?.url || '',
  title: track.title || '',
  artist: track.originalArtists[0].name || '',
  loading: false
});

/**
 * Returns a queue of scheduled tracks for the selected entity.
 */
export const mapScheduledTrackData = (
  eligibleTracklist: DiscoveryModeTracksFieldsFragment[],
  selectedEntity?: DiscoveryModeEntity
): ScheduledTrackData[] => {
  return eligibleTracklist
    .filter(track => track.enabled)
    .filter(track => track.enabledBy?.name === selectedEntity?.name) // TODO: switch to id after endpoint filter is updated
    .map(t => transformScheduledTrack(t.track))
    .slice(0, 3);
};
