import React from 'react';
import styled from 'styled-components';

import { StyledTombstoneLight, StyledTombstoneDark } from 'components/Tombstone';

interface ImageWrapperProps {
  $size?: AvatarProps['size'];
  $circle?: AvatarProps['circle'];
  $heightAuto?: AvatarProps['heightAuto'];
  $imageRatio?: AvatarProps['imageRatio'];
  $imageUrl?: AvatarProps['imageUrl'];
}

const ImageWrapper = styled.div<ImageWrapperProps>`
  border-radius: ${({ $circle }) => ($circle ? '99rem' : '0.3rem')};
  box-shadow:
    0 0 1px rgba(0, 0, 0, 0.5),
    0 0 0 rgba(0, 0, 0, 0.1);
  flex: 0 0 auto;
  height: ${({ $size, $heightAuto }) => (!$heightAuto ? $size : 'auto')};
  padding-bottom: ${({ $imageRatio, $size }) => $imageRatio || $size};
  position: relative;
  transform: scale(1);
  transition: box-shadow 0.3s ease-in-out;
  width: ${({ $size }) => $size};

  img {
    background: #f0f0f0; // if image fails to load, this background-color will show
    border-radius: ${({ $circle }) => ($circle ? '99rem' : '0.2rem')};
    left: 0;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &:hover {
    box-shadow:
      0 0 1px rgba(0, 0, 0, 0.5),
      0 0 10px rgba(0, 0, 0, 0.1);
  }
`;

interface AvatarTombstoneProps {
  $dark?: boolean;
  $size?: AvatarProps['size'];
  $circle?: AvatarProps['circle'];
  $heightAuto?: AvatarProps['heightAuto'];
  $imageRatio?: AvatarProps['imageRatio'];
}

const AvatarTombstone = styled(ImageWrapper)<AvatarTombstoneProps>`
  ${({ $dark }) => ($dark ? StyledTombstoneDark : StyledTombstoneLight)}
`;

/**
 * [Avatar] - Handles rendering artist image and default image if no url provided
 *
 */

export type AvatarProps = {
  //  * @param {string} altTag - Description of image, typically entity (aka artist) name
  altTag?: string;
  //  * @param {bool} [circle] - Makes the avatar a circle v square. Used for images of people.
  circle?: boolean;
  title?: string;
  className?: string;
  dark?: boolean;
  imageRatio?: string;
  //  * @param {bool} [heightAuto] - Defaults wrapper to height: auto;. Used when parent wrapper
  //  * is flexbox and using SIZES.FULL
  heightAuto?: boolean;
  //  * @param {string} [imageUrl] - Url if artist has ones
  imageUrl?: string | null;
  //  * @param {bool} [loading] - If true, displays tombstone
  loading?: boolean;
  //  * @param {number} [size] - Number in 'rem' for Avatar height & width. Try to use static defaults.
  size?: string;
  lazy?: boolean;
  overflow?: boolean;
  dataTestId?: string;
};

const Avatar = React.memo(
  ({
    altTag,
    title,
    className,
    circle,
    dark,
    imageRatio,
    heightAuto,
    imageUrl,
    loading,
    size = SIZES.MEDIUM,
    lazy = true,
    dataTestId
  }: AvatarProps) => {
    if (loading) {
      return (
        <AvatarTombstone
          $dark={dark}
          $size={size}
          $heightAuto={heightAuto}
          $circle={circle}
          $imageRatio={imageRatio}
        />
      );
    }

    return (
      <ImageWrapper
        $size={size}
        $circle={circle}
        className={className}
        $heightAuto={heightAuto}
        $imageRatio={imageRatio}
        $imageUrl={imageUrl}
        data-testid={dataTestId}
      >
        {imageUrl && (
          <img
            src={imageUrl}
            alt={altTag}
            title={title}
            loading={lazy ? 'lazy' : 'eager'}
          />
        )}
      </ImageWrapper>
    );
  }
);

const SIZES = {
  TINY: '2.2rem',
  SMALL: '3rem',
  MEDIUM: '4rem',
  LARGE: '6rem',
  XLARGE: '18rem',
  FULL: '100%'
} as const;

export default Object.assign(Avatar, { SIZES });
