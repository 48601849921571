import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const GenreSearchResult = ({ slug = '', image_url, matched, hideSearchResults }) => (
  <Link to={slug} onClick={hideSearchResults}>
    <div className="UniversalSearchResultRow__tile" data-testid={GenreSearchResult.TEST_IDS.ROW}>
      <img src={image_url} alt="Genre Background" />
      <div dangerouslySetInnerHTML={{ __html: matched.genre }} />
    </div>
  </Link>
);

GenreSearchResult.propTypes = {
  slug: PropTypes.string,
  image_url: PropTypes.string,
  matched: PropTypes.shape({
    genre: PropTypes.string
  }),
  hideSearchResults: PropTypes.func
};

GenreSearchResult.TEST_IDS = {
  ROW: 'genre-search-result-row'
};

export default GenreSearchResult;
