import React, { useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

import { COLORS } from 'config/constants';

import Button from 'components/Button';
import {
  BORDER_COLOR,
  BORDER_RADIUS_REM,
  BORDER_THICKNESS_REM,
  DISABLED_BACKGROUND_COLOR
} from 'components/Form/Inputs/styled';
import TextInput from 'components/Form/Inputs/Text/Text';
import Icon from 'components/Icon';
import TextHeader from 'components/TextHeader';
import { copyTextToClipboard } from 'helpers/text';

export const StyledLinkContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

const ShareUrlInput = styled(TextInput)`
  flex: 1;
  margin-bottom: 0;
`;

const ConditionalLink = styled.a`
  width: 100%;

  &:hover input {
    text-decoration: underline;
    cursor: pointer;
    border-color: ${BORDER_COLOR};
  }
`;

export const StyledButton = styled(Button)`
  background: linear-gradient(
    to left,
    ${rgba(DISABLED_BACKGROUND_COLOR, 1)} 80%,
    ${rgba(DISABLED_BACKGROUND_COLOR, 0.98)}
  );
  bottom: ${BORDER_THICKNESS_REM};
  border-radius: ${BORDER_RADIUS_REM};
  border-left: 1px solid ${BORDER_COLOR};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: absolute;
  right: ${BORDER_THICKNESS_REM};
  top: ${BORDER_THICKNESS_REM};
  transition: background 0.3s ease;

  &:hover {
    background: ${COLORS.GRAY_LIGHTEST};
  }
`;

interface Props {
  text: string;
  title?: string;
  content?: string;
  hyperlink?: boolean;
  testId?: string;
  showClipboard?: boolean;
  className?: string;
  onCopy?: () => void;
}

const CopyToClipboardTextInput = ({
  text,
  title,
  content,
  hyperlink = false,
  testId = '',
  showClipboard = true,
  className,
  onCopy = () => {}
}: Props) => {
  const [isCopied, setCopied] = useState(false);
  const copyButtonColor = isCopied ? COLORS.PRIMARY : COLORS.GRAY_DARK;

  const inputRef = React.createRef();

  return (
    <StyledLinkContainer className={className}>
      <ConditionalLink
        href={text}
        target="_blank"
        rel="noopener noreferrer"
        as={hyperlink ? 'a' : 'div'}
      >
        <ShareUrlInput
          name="share-url"
          ref={inputRef}
          readOnly={true}
          value={text}
          title={title || text}
          data-testid={testId}
        />
      </ConditionalLink>

      {showClipboard && (
        <StyledButton
          color={Button.COLORS.TRANSPARENT}
          onClick={async () => {
            let copySuccess = false;
            if (navigator.clipboard) {
              try {
                await navigator.clipboard.writeText(text);
                copySuccess = true;
              } catch {
                void 0;
              }
            } else {
              copySuccess = copyTextToClipboard(text);
            }
            if (!copySuccess) {
              console.error('Failed to copy to clipboard');
              return;
            }
            setCopied(true);
            onCopy();
            setTimeout(() => setCopied(false), 3000);
          }}
        >
          <Icon
            type={Icon.TYPES.COPY}
            width={12}
            color={copyButtonColor}
            className="mr-1"
          />
          <TextHeader
            type={TextHeader.TYPES.XSMALL}
            className="mb-0"
            style={{ color: copyButtonColor }}
          >
            {content && !isCopied ? (
              <span>{content}</span>
            ) : isCopied ? (
              'COPIED!'
            ) : (
              'COPY'
            )}
          </TextHeader>
        </StyledButton>
      )}
    </StyledLinkContainer>
  );
};

export default CopyToClipboardTextInput;
