import React from 'react';

import { useDiscoveryMode } from '../DiscoveryModeContext';
import useBreakpoint from 'hooks/useBreakpoint';

import {
  Badge,
  BadgeVariants,
  Banner,
  BannerVariant,
  Button,
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
  TextEllipsis,
  Tombstone,
  TooltipTrigger
} from '@protonradio/proton-ui';
import Flex from 'components/Flex';
import { PacmanIcon } from 'components/Icons';
import Avatar from 'components/Avatar';
import TextHeader from 'components/TextHeader';
import CommaList from 'components/CommaList';
import {
  StyledTrackMeta,
  StyledBottomMeta
} from 'components/TracksTable/TrackMobileTileMeta';
import { Container } from 'components/Layout';

import type { DiscoveryModeTracksFieldsFragment } from 'gql/graphql';
import { COLORS } from '@protonradio/proton-ui/constants';
import Icon from 'components/Icon';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { StyledTooltipContent } from '../Modal/styles';
import { intercomPaths } from '../DiscoveryMode';

interface TableComponentsProps {
  trackCampaignData: DiscoveryModeTracksFieldsFragment;
  onPress?: () => void;
  isDisabled?: boolean;
  'data-testid'?: string;
}

/**
 * Button to schedule, unschedule or control invite modal for a track.
 * @note This button is disabled if track is scheduled by another entity.
 */
export const TrackScheduleButton = ({
  trackCampaignData,
  onPress,
  isDisabled,
  'data-testid': testId
}: TableComponentsProps) => {
  const { selectedEntity, scheduledQueue } = useDiscoveryMode();
  const queue = scheduledQueue.queue;
  const track = trackCampaignData.track;
  const trackInQueue = queue.find(t => t.id === track.id);
  const trackIsScheduled = trackInQueue || trackCampaignData.enabled;
  const trackIsLoading = trackInQueue?.loading;
  const isTrackApproved = trackCampaignData.approved;

  if (!selectedEntity?.isOptedIn) {
    return null;
  }

  if (!isTrackApproved) {
    return (
      <Button onPress={onPress} variant="secondary">
        Manage Opt Ins
      </Button>
    );
  }

  return (
    <Button
      data-testid={testId}
      variant={trackIsScheduled ? 'danger' : 'primary'}
      isDisabled={
        isDisabled ||
        trackIsLoading === 'adding' ||
        trackIsLoading === 'removing' ||
        Boolean(
          trackCampaignData.enabledBy &&
            trackCampaignData.enabledBy?.name !== selectedEntity?.name //disable tracks not scheduled by the selected entity
        )
      }
      onPress={onPress}
    >
      <div style={{ minWidth: '100px' }}>
        {trackIsLoading ? (
          <Flex align="center">
            {trackIsLoading === 'adding' ? 'Scheduling' : 'Unscheduling'}
            <PacmanIcon
              color={COLORS.GRAYSCALE.GRAY_SUPER_LIGHT}
              size={16}
              className="ml-1"
            />
          </Flex>
        ) : trackIsScheduled ? (
          <span>Unschedule</span>
        ) : (
          <span>Schedule</span>
        )}
      </div>
    </Button>
  );
};

export const TrackCellData = ({ trackCampaignData }: TableComponentsProps) => {
  const track = trackCampaignData.track;
  const titleString = `${track.title}${track.version ? ` (${track.version})` : ''}`;

  return (
    <Flex>
      <Link to={track.release?.slug || ''}>
        <Avatar imageUrl={track.release?.coverArt?.url} />
      </Link>
      <div className="ml-2">
        <TextHeader
          type={TextHeader.TYPES.MEDIUM}
          weight={TextHeader.WEIGHTS.NORMAL}
          style={{ margin: 0, display: 'flex', alignItems: 'center' }}
        >
          <TextEllipsis maxWidth="520px">
            <Link to={track.release?.slug || ''} title={titleString}>
              {titleString}
            </Link>
          </TextEllipsis>
        </TextHeader>
        <CommaList
          attribute="name"
          urlAttribute={artist => artist.slug}
          data={[
            ...track.originalArtists.map(a => ({ name: a.name, slug: a.slug })),
            ...track.featuredArtists.map(a => ({ name: a.name, slug: a.slug }))
          ]}
        />
      </div>
    </Flex>
  );
};

export const MobileTrackCellData = ({ trackCampaignData }: TableComponentsProps) => {
  const track = trackCampaignData.track;
  const titleString = `${track.title}${track.version ? ` (${track.version})` : ''}`;

  return (
    <Flex>
      <Avatar imageUrl={track.release?.coverArt?.url} size={Avatar.SIZES.LARGE} />
      <StyledTrackMeta>
        <TextHeader
          type={TextHeader.TYPES.MEDIUM}
          weight={TextHeader.WEIGHTS.NORMAL}
          style={{ lineHeight: 1.1, marginBottom: '2px' }}
          truncate
        >
          {titleString}
        </TextHeader>
        <div style={{ color: COLORS.GRAYSCALE.GRAY_MEDIUM }}>
          <TextEllipsis maxWidth="100%">
            <CommaList
              data={[
                ...track.originalArtists.map(a => a.name),
                ...track.featuredArtists.map(a => a.name)
              ]}
            />
          </TextEllipsis>
        </div>
        <StyledBottomMeta>
          <TextHeader
            type={TextHeader.TYPES.XSMALL}
            color={TextHeader.COLORS.SECONDARY}
            uppercase
            truncate
          >
            {track?.release?.label?.name}
          </TextHeader>
        </StyledBottomMeta>
      </StyledTrackMeta>
    </Flex>
  );
};

const StyledTableBanner = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${COLORS.GRAYSCALE.GRAY_LIGHT};
  padding: 1rem;
  border-radius: 1rem;
  margin-top: 2rem;
  * > .proton-Badge {
    font-size: 0.96rem;
  }
`;

export const MobileDiscoveryModeCellMeta = ({
  trackCampaignData
}: TableComponentsProps) => {
  return (
    <StyledTableBanner>
      <Table>
        <TableHeader>
          <Column>Scheduled?</Column>
          <Column>Spotify Recommended?</Column>
          <Column>Intent Rate</Column>
        </TableHeader>
        <TableBody>
          <Row>
            <Cell>
              {trackCampaignData.enabled ? (
                <Badge variant={BadgeVariants.success}>Scheduled</Badge>
              ) : trackCampaignData.approved ? (
                <Badge variant={BadgeVariants.success}>Ready</Badge>
              ) : null}
            </Cell>
            <Cell>
              <ConditionalDash condition={trackCampaignData.spotifyRecommend}>
                <Icon
                  type={Icon.TYPES.LIGHTNING_BOLT}
                  color={COLORS.SUCCESS.MEDIUM}
                  width={16}
                />
              </ConditionalDash>
            </Cell>
            <Cell>
              <ConditionalDash condition={Boolean(trackCampaignData?.intentRate)}>
                <Flex align="center">
                  <TextHeader
                    type={TextHeader.TYPES.XSMALL}
                    color={TextHeader.COLORS.SECONDARY}
                    uppercase
                    truncate
                  >
                    {trackCampaignData.intentRate}%
                  </TextHeader>
                  <Icon
                    type={Icon.TYPES.TROPHY}
                    color="gold"
                    width={14}
                    style={{ transform: 'translate(6px, 3px)' }}
                  />
                </Flex>
              </ConditionalDash>
            </Cell>
          </Row>
        </TableBody>
      </Table>
    </StyledTableBanner>
  );
};

export const ConditionalDash = ({
  condition,
  children
}: {
  condition: boolean;
  children: React.ReactNode;
}) => {
  return !condition ? <span>–</span> : children;
};

export const IntentRateTooltip = ({ children }: { children: React.ReactNode }) => {
  return (
    <TooltipTrigger
      content={
        <a href={intercomPaths.intentRate} target="_blank">
          <Flex align="center">
            <StyledTooltipContent>What is Intent Rate? </StyledTooltipContent>
            <Icon
              type={Icon.TYPES.EXTERNAL_LINK}
              width={8}
              className="mr-1"
              color={COLORS.BRAND.PRIMARY}
            />
          </Flex>
        </a>
      }
      placement="top"
      arrow
    >
      {children}
    </TooltipTrigger>
  );
};

export const TableTombstones = () => {
  const isSmallScreen = useBreakpoint(
    useBreakpoint.BREAKPOINTS.MEDIUM,
    useBreakpoint.DIRECTIONS.DOWN
  );

  if (isSmallScreen) {
    return (
      <div aria-label="Loading table">
        <Container>
          {[...Array(3)].map((_, i) => (
            <div
              key={`Tombstone ${i + 1}`}
              style={{ padding: '1rem', display: 'flex', alignItems: 'center' }}
            >
              <Avatar loading altTag="Artist avatar" size={Avatar.SIZES.LARGE} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.5rem',
                  marginLeft: '1rem'
                }}
              >
                <Tombstone width="18rem" />
                <Tombstone width="14rem" />
                <Tombstone width="12rem" />
              </div>
            </div>
          ))}
        </Container>
      </div>
    );
  }

  return (
    <Table aria-label="Loading table">
      <TableHeader>
        <Column>Track</Column>
        <Column>Label</Column>
        <Column>Remix Artists</Column>
        <Column>Intent Rate</Column>
        <Column>Scheduled</Column>
        <Column>{''}</Column>
      </TableHeader>

      <TableBody>
        {[...Array(3)].map((_, i) => (
          <Row key={`Tombstone ${i + 1}`}>
            <Cell>
              <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <Avatar loading />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                    marginLeft: '0.6rem'
                  }}
                >
                  <Tombstone width="18rem" />
                  <Tombstone width="14rem" />
                </div>
              </div>
            </Cell>
            <Cell>–</Cell>
            <Cell>–</Cell>
            <Cell>–</Cell>
            <Cell>–</Cell>
            <Cell>
              <div style={{ width: '160px' }}></div>
            </Cell>
          </Row>
        ))}
      </TableBody>
    </Table>
  );
};

interface TableBannerProps {
  variant: BannerVariant;
  title: string;
  content?: string;
  actionText?: string;
  onAction?: () => void;
  testId: string;
  compact?: boolean;
}

export const DiscoveryModeBanner = ({
  variant,
  title,
  content,
  actionText,
  onAction,
  compact = true,
  testId
}: TableBannerProps) => {
  return (
    <Banner variant={variant} icon data-testid={testId} compact={compact}>
      <Banner.Title>{title}</Banner.Title>
      {content && <Banner.Content>{content}</Banner.Content>}
      {actionText && onAction && (
        <Banner.Actions>
          <Banner.Action onPress={onAction} variant="secondary">
            {actionText}
          </Banner.Action>
        </Banner.Actions>
      )}
    </Banner>
  );
};
