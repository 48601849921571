import { graphql } from 'gql';

export const DiscoveryModeConfigsQuery = graphql(`
  query DiscoveryModeConfigsQuery {
    viewer {
      id
      managedLabels {
        id
        name
        configs {
          hasDiscoveryModeEnabled
        }
        logo(size: SMALL) {
          url
        }
      }
      artists {
        id
        name
        image {
          url
        }
        configs {
          hasDiscoveryModeEnabled
        }
      }
      configs {
        hasSeenDiscoveryModeOnboarding
      }
    }
  }
`);

export const DiscoveryModeTracksFragment = graphql(`
  fragment DiscoveryModeTracksFields on DiscoveryModeTrack {
    enabled
    approved
    id
    intentRate
    listeners
    listenersLift
    saves
    spotifyRecommend
    streams
    streamsLift
    enabledBy {
      id
      name
      type
      slug
      hasDiscoveryModeEnabled
    }
    entities {
      id
      name
      type
      slug
      hasDiscoveryModeEnabled
    }
    track {
      id
      title
      version
      originalArtists {
        id
        name
        slug
        image {
          url
        }
      }
      featuredArtists {
        id
        name
        slug
        image {
          url
        }
      }
      remixArtists {
        id
        name
        slug
        image {
          url
        }
      }
      release {
        id
        slug
        coverArt(size: SMALL) {
          url
        }
        label {
          id
          name
          slug
          logo(size: SMALL) {
            url
          }
        }
      }
    }
  }
`);

export const DiscoveryModeStateQuery = graphql(`
  query DiscoveryMode {
    discoveryMode {
      nextSchedulingStartsAt
      runningCampaign {
        id
        title
        startDate
        endDate
        state
        schedulingStartsAt
        schedulingEndsAt
      }
      upcomingCampaign {
        id
        title
        startDate
        endDate
        state
        schedulingStartsAt
        schedulingEndsAt
      }
    }
  }
`);

export const DiscoveryModeEligibleTracksQuery = graphql(`
  query DiscoveryModeEligibleTracks($filter: DiscoveryModeTrackFilterInput) {
    discoveryMode {
      upcomingCampaign {
        eligibleTracks(filterBy: $filter) {
          ...DiscoveryModeTracksFields
        }
      }
    }
  }
`);

export const DiscoveryModeEnabledTracksQuery = graphql(`
  query DiscoveryModeEnabledTracks {
    discoveryMode {
      runningCampaign {
        id
        enabledTracks {
          ...DiscoveryModeTracksFields
        }
      }
    }
  }
`);

//TODO: We need an endpoint that only returns last 3 scheduled tracks
export const DiscoveryModeScheduledTracksQuery = graphql(`
  query DiscoveryModeScheduledTracks {
    discoveryMode {
      upcomingCampaign {
        id
        eligibleTracks {
          ...DiscoveryModeTracksFields
        }
      }
    }
  }
`);
