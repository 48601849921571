import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Badge, Banner, Button } from '@protonradio/proton-ui';

import { useAppDispatch } from 'hooks/redux';
import { showAlert } from 'redux/actions/ui';

import SpotifyLogo from 'components/Icons/SpotifyLogo';
import { NavTab, NavTabContainer, NavTabGroup } from 'components/Nav';
import TabbedHeaderLayout from 'components/TabbedHeaderLayout';

import routeMap from 'config/routes';
import { ToggleDiscoveryModeMutation } from 'graphql/mutations/discoveryMode';
import useMutationWithAlert from 'hooks/graphql/useMutationWithAlert';
import useBreakpoint from 'hooks/useBreakpoint';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import { RouteNotFound } from 'routes/ErrorPage';
import CountdownTimer from './CountdownTimer';
import { discoveryModeQueryParams, useDiscoveryMode } from './DiscoveryModeContext';
import DiscoveryModeFilterControls from './DiscoveryModeFilterControls';
import { DiscoveryModeHistoryState } from './Modal/types';
import SchedulingSidebar from './Scheduling/SchedulingSidebar';
import {
  DISCOVERY_MODE_BREAKPOINT,
  GradientGreenBackground,
  LogoTitleWrapper,
  VerticalLayout
} from './styles';
import EligibleTracks from './Tables/EligibleTracks';
import EnabledTracks from './Tables/EnabledTracks';
import { DiscoveryModeBanner, TableTombstones } from './Tables/TableComponents';

const HeaderAction = styled.span`
  font-size: 1.4rem;
  height: 2rem;
  display: flex;
  align-items: center;
`;

const NavTabDisabled = styled.span`
  margin-left: 8px;
  opacity: 0.5;
  pointer-events: none;
`;

export const intercomPaths = {
  faq: 'https://intercom.help/proton-radio/en/articles/9320898-spotify-discovery-mode-beta-faq',
  eligible:
    'https://intercom.help/proton-radio/en/articles/9320898-spotify-discovery-mode-beta-faq#h_1616b3d376',
  intentRate:
    'https://intercom.help/proton-radio/en/articles/11005396-spotify-discovery-mode-intent-rate'
};

export const settingsPaths = {
  notificationSettings: '/account/notifications#discovery-mode',
  optInSettings: '/account/discovery-mode'
};

export const paths = {
  eligible: `/discovery-mode/eligible`,
  scheduled: `/discovery-mode/scheduled`,
  enabled: `/discovery-mode/enabled`,
  insights: `/discovery-mode/insights`,
  default: `/discovery-mode/eligible`
};

const DiscoveryMode = () => {
  const {
    initialLoading,
    tracksLoading,
    error,
    eligibleTracklist,
    enabledTracklist,
    discoveryMode,
    selectedEntity,
    hasSeenOnboarding,
    isBeforeScheduling,
    isAfterScheduling,
    isEnabledRoute
  } = useDiscoveryMode();
  const { i: inviter, updateParams } = useUrlQueryParams(discoveryModeQueryParams);
  const dispatch = useAppDispatch();
  const history = useHistory<DiscoveryModeHistoryState>();
  const [{ fetching: isOptingIn }, executeMutation] = useMutationWithAlert(
    ToggleDiscoveryModeMutation
  );
  const isMobile = useBreakpoint(
    DISCOVERY_MODE_BREAKPOINT,
    useBreakpoint.DIRECTIONS.DOWN
  );

  const isOptedIn = selectedEntity?.isOptedIn;
  const historyState = history.location.state;
  const pathname = history.location.pathname;
  const runningCampaignTitle = discoveryMode?.runningCampaign?.title.split(' ')[0];
  const upcomingCampaignTitle = discoveryMode?.upcomingCampaign?.title.split(' ')[0];
  const showInviteBanner =
    !isOptedIn && inviter.length > 0 && inviter !== selectedEntity?.name;
  const showSidebar =
    !isBeforeScheduling && !isAfterScheduling && pathname === paths.eligible;

  // Handle opt-in success
  useEffect(() => {
    if (historyState?.optInSuccess) {
      dispatch(
        showAlert({
          message:
            "You're opted in to Discovery Mode! You can always click on the chat bubble to let us know if you have any questions.",
          timeout: 10000
        })
      );
      updateParams({ i: undefined }, 'replaceIn');
      history.replace({
        pathname: history.location.pathname,
        search: history.location.search,
        state: { ...historyState, optInSuccess: false }
      });
    }
  }, [historyState?.optInSuccess]);

  const handleOptIn = async () => {
    const isEntityArtist = selectedEntity?.type === 'artist';
    if (!hasSeenOnboarding) {
      history.replace({
        pathname: '/dm-opt-in',
        state: {
          modal: true,
          modalLocation: '/discovery-mode'
        }
      });
      return null;
    }
    const result = await executeMutation({
      ...(isEntityArtist
        ? { artistId: selectedEntity?.id }
        : { labelId: selectedEntity?.id }),
      enabled: true
    });

    if (result.hasErrors) return;

    history.replace({
      pathname: history.location.pathname,
      state: { ...historyState, optInSuccess: true }
    });
  };

  const renderBanner = () => {
    if (isEnabledRoute) return null;

    if (showInviteBanner) {
      return (
        <DiscoveryModeBanner
          variant="success"
          title={`You've been invited to try Discovery Mode by ${decodeURIComponent(inviter)}`}
          actionText="Get Started!"
          onAction={() => {
            history.replace({
              pathname: '/dm-opt-in',
              search: history.location.search,
              state: {
                modal: true,
                modalLocation: '/discovery-mode'
              }
            });
          }}
          testId="DiscoveryMode-Banner-Invited"
        />
      );
    }

    if (isBeforeScheduling || isAfterScheduling) {
      return (
        <DiscoveryModeBanner
          variant="warning"
          title={`Submission deadline for ${runningCampaignTitle} has passed!`}
          testId="DiscoveryMode-Banner-Warning"
          compact={false}
        />
      );
    }

    if (isOptedIn === false && !showInviteBanner && !isOptingIn) {
      return (
        <DiscoveryModeBanner
          variant="warning"
          title={`${selectedEntity?.name} is not opted in to Discovery Mode`}
          content="You must opt in to Discovery Mode to use it."
          actionText={isOptingIn ? 'Opting In...' : 'Opt In'}
          onAction={handleOptIn}
          testId="DiscoveryMode-Banner-OptIn"
        />
      );
    }

    return null;
  };

  return (
    <TabbedHeaderLayout
      container={isMobile ? false : true}
      renderSidebar={() => showSidebar && <SchedulingSidebar />}
      header={{
        title: (
          <LogoTitleWrapper>
            <SpotifyLogo />
            <span>Discovery Mode</span>
          </LogoTitleWrapper>
        ),
        customBackground: <GradientGreenBackground />,
        seo: {
          title: 'Discovery Mode',
          description: 'A dashboard to manage your Spotify Discovery Mode tracks',
          section: 'Discovery Mode'
        },
        renderTabs: () => (
          <NavTabContainer>
            <NavTabGroup>
              <NavTab
                title="Eligible Tracks"
                path={paths.eligible}
                aside={<Badge variant="secondary">{eligibleTracklist.length}</Badge>}
              />
              <NavTab
                title="Enabled Tracks"
                path={paths.enabled}
                aside={
                  <Badge variant="secondary">
                    {enabledTracklist.filter(track => track.enabled).length}
                  </Badge>
                }
              />
              <NavTabDisabled>
                <NavTab
                  title="Insights"
                  path={paths.insights}
                  aside={<Badge variant="secondary">COMING SOON</Badge>}
                />
              </NavTabDisabled>
            </NavTabGroup>
          </NavTabContainer>
        ),
        renderHeaderActions: () => (
          <Button variant="translucent" to={intercomPaths.faq}>
            <HeaderAction>Learn More?</HeaderAction>
          </Button>
        )
      }}
      renderMain={() => {
        if (error) {
          return (
            <div className="mt-1 mb-2">
              <Banner
                icon
                variant="danger"
                compact={false}
                data-testid="DiscoveryMode-Banner-Error"
              >
                <Banner.Title>Oops!</Banner.Title>
                <Banner.Content>
                  There was an error loading your Discovery Mode data. Please try again
                  later.
                </Banner.Content>
              </Banner>
            </div>
          );
        }

        if (initialLoading || isOptingIn || (isBeforeScheduling && initialLoading)) {
          return (
            <VerticalLayout>
              <DiscoveryModeFilterControls />
              <TableTombstones />
            </VerticalLayout>
          );
        }

        return (
          <VerticalLayout id="discovery-mode-layout">
            <DiscoveryModeFilterControls />
            {tracksLoading && selectedEntity ? (
              <TableTombstones />
            ) : (
              <>
                {renderBanner()}
                <Switch>
                  <Route
                    exact
                    path={paths.eligible}
                    render={() => (
                      <EligibleTracks
                        isMobile={isMobile}
                        isDisabled={
                          isOptedIn === false || !selectedEntity || isAfterScheduling
                        }
                        countdownTimer={
                          isBeforeScheduling && (
                            <CountdownTimer
                              title={upcomingCampaignTitle}
                              schedulingStartsAt={discoveryMode?.nextSchedulingStartsAt}
                            />
                          )
                        }
                      />
                    )}
                  />
                  <Route exact path={paths.enabled} render={() => <EnabledTracks />} />
                  <Redirect from={routeMap.discoveryMode} to={paths.default} />
                  <Redirect from={paths.insights} to={paths.default} />
                  <RouteNotFound />
                </Switch>
              </>
            )}
          </VerticalLayout>
        );
      }}
    />
  );
};

export default DiscoveryMode;
