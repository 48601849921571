import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import { selectHasPromoInboxAccess } from 'redux/selectors/promo';
import { selectPromoReleaseIdsFollowingRoot } from 'redux/selectors/promoReleases';
import { selectUserLabelProfiles } from 'redux/selectors/user';

import { fetchLabelSubscribersForLabels as fetchLabelSubscribersForLabelsAction } from 'redux/actions/labelSubscribers';
import { getPromoReleasesFollowing as getPromoReleasesAction } from 'redux/actions/promoReleases';

import { SUBSCRIPTION_STATES } from 'config/constants';
import { isDataStale } from 'helpers/data';
import { isProUser } from 'helpers/user';
import usePrevious from 'hooks/usePrevious';
import { useQuery } from 'urql';

const promoLabelsQuery = `
  query getPromoSubscriptionCounts {
    viewer {
      id
      promoPool {
        following: labels(status: ${SUBSCRIPTION_STATES.FOLLOWING}) {
          pageInfo {
            nodeCount
          }
        }
        invited: labels(status: ${SUBSCRIPTION_STATES.NEW_INVITES}) {
          pageInfo {
            nodeCount
          }
        }
      }
    }
  }
`;

/**
 * [PromoDataFetcher]
 * Simple component that fetches all promo tracks if:
 * 1) pro user signs in
 * 2) promo label subscriptions are muted or unmuted
 *
 * Tracks are saved in promos reducer so that we can show them which tracks are
 * available to rate and download from anywhere in the app
 */

const PromoDataFetcher = ({
  // Redux props
  user,
  labelProfiles,
  promoReleases,
  hasPromoInboxAccess,
  // Redux actions
  getPromoReleases,
  fetchLabelSubscribersForLabels
}) => {
  // NOTE: prevUser will be undefined on app startup
  const prevUser = usePrevious(user, {});
  const userChanged = user.user_id !== prevUser.user_id;

  const [queryResult] = useQuery({
    query: promoLabelsQuery,
    pause: !user.user_id || !hasPromoInboxAccess
  });

  const data = queryResult?.data || {};
  const labelsFollowing = data.viewer?.promoPool?.following?.pageInfo?.nodeCount || 0;
  const prevLabelsFollowing = usePrevious(labelsFollowing, 0);
  const labelsFollowingChanged = labelsFollowing !== prevLabelsFollowing;

  // TODO: We need to fetch promo releases again once a label is followed/unfollowed. This will eventually be handled by
  // using gql to power promo tracks... it looks like we might be showing "invited" tracks as promo tracks
  useEffect(() => {
    if (labelsFollowingChanged) {
      getPromoReleases();
    }
  }, [user, getPromoReleases, labelsFollowingChanged]);

  // Ensure that when the user logs in we grab their promo releases / tracks so that we
  // know which tracks to display the promo download & reaction widget for.
  useEffect(() => {
    if (!userChanged || !user.user_id || !isProUser(user) || !hasPromoInboxAccess) return;

    // Don't bother refetching if we have recent data (5 min)
    const STALE_MINUTES = 5;

    if (isDataStale(promoReleases.__lastFetched, STALE_MINUTES)) {
      getPromoReleases();
    }
  }, [user, userChanged, promoReleases, getPromoReleases, hasPromoInboxAccess]);

  // For each label that the user manages, we want to fetch their label subscribers.
  // This is used for things such as:
  // 1) Determining whether we should show an artist's feedback tab (if artist is a promo subscriber)
  // 2) Will be referenced when adding features to add artist as promo subscribers from throught the app (FUTURE)
  useEffect(() => {
    if (!userChanged || !isProUser(user)) return;

    const STALE_MINUTES = 5;
    const labelIds = labelProfiles.map(({ id }) => id);

    fetchLabelSubscribersForLabels(labelIds, STALE_MINUTES);
  }, [user, userChanged, labelProfiles, fetchLabelSubscribersForLabels]);

  return null;
};

PromoDataFetcher.propTypes = {
  // connect state
  user: PropTypes.shape({
    user_id: PropTypes.number
  }),
  promoReleasesLastFetched: PropTypes.number,
  promoReleases: PropTypes.shape({
    __lastFetched: PropTypes.number,
    __isFetching: PropTypes.bool
  }),
  // connect actions
  getPromoReleases: PropTypes.func,
  getNewInvites: PropTypes.func,
  fetchLabelSubscribersForLabels: PropTypes.func
};

export default connect(
  state => ({
    user: state.user,
    labelProfiles: selectUserLabelProfiles(state),
    hasPromoInboxAccess: selectHasPromoInboxAccess(state),
    promoReleases: selectPromoReleaseIdsFollowingRoot(state)
  }),
  {
    getPromoReleases: getPromoReleasesAction,
    fetchLabelSubscribersForLabels: fetchLabelSubscribersForLabelsAction
  }
)(PromoDataFetcher);
