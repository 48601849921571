import type { AvatarProps } from '../Avatar';

import React from 'react';

import DefaultReleaseImage from 'images/icon-track-cover.svg';
import Avatar from '../Avatar';

/**
 * [ReleaseAvatar] - handles rendering Avatar with defaults specific to the Release entity
 * NOTE: currently almost exactly the same as Label Entity (minus alt tag)
 *
 * See Avatar for other props available
 */

type ReleaseAvatarProps = AvatarProps & {
  imageUrl?: string;
  name?: string;
};

const ReleaseAvatar = ({
  altTag = 'Release Avatar',
  imageUrl = '',
  name = '',
  ...rest
}: ReleaseAvatarProps) => {
  const avatarUrl: string = imageUrl
    ? encodeURI(imageUrl)
    : (DefaultReleaseImage as string);

  return (
    <Avatar
      imageUrl={avatarUrl}
      altTag={altTag}
      title={name}
      dataTestId={ReleaseAvatar.TEST_IDS.CONTAINER}
      {...rest}
    />
  );
};

ReleaseAvatar.TEST_IDS = {
  CONTAINER: 'ReleaseAvatar'
};

export default ReleaseAvatar;
