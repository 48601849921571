import React from 'react';
import {
  Table,
  TableHeader,
  Column,
  TableBody,
  Row,
  Cell,
  COLORS
} from '@protonradio/proton-ui';
import CommaList from 'components/CommaList';
import Flex from 'components/Flex';
import Icon from 'components/Icon';
import { useDiscoveryMode } from '../DiscoveryModeContext';
import { TablePlaceholder } from '../styles';
import useBreakpoint from 'hooks/useBreakpoint';
import type { DiscoveryModeTracksFieldsFragment } from 'gql/graphql';
import {
  ConditionalDash,
  IntentRateTooltip,
  MobileTrackCellData,
  TrackCellData
} from './TableComponents';
import { intercomPaths } from '../DiscoveryMode';

interface EnabledTracksTableProps {
  tracklist: DiscoveryModeTracksFieldsFragment[];
}

const DesktopEnabledTracksTable = ({ tracklist }: EnabledTracksTableProps) => {
  return (
    <div className="Table mt-0">
      <Table>
        <TableHeader>
          <Column>Track</Column>
          <Column>Label</Column>
          <Column>Remix Artists</Column>
          <Column>
            <Flex align="center">
              Intent Rate{' '}
              <IntentRateTooltip>
                <Icon
                  type={Icon.TYPES.INFO}
                  width={14}
                  className="ml-1"
                  color={COLORS.GRAYSCALE.GRAY_MEDIUM}
                />
              </IntentRateTooltip>
            </Flex>
          </Column>
          <Column>Auto Renew</Column>
        </TableHeader>
        <TableBody>
          {tracklist?.map((rowData, index) => {
            const performance = rowData;
            const track = performance.track;

            return (
              <Row key={index + 1} data-testid="row">
                <Cell>
                  <TrackCellData trackCampaignData={rowData} />
                </Cell>
                <Cell>{track.release?.label?.name}</Cell>
                <Cell>
                  <ConditionalDash condition={Boolean(track.remixArtists.length)}>
                    <CommaList data={track.remixArtists.map(a => a.name)} />
                  </ConditionalDash>
                </Cell>
                <Cell>
                  <ConditionalDash condition={Boolean(performance.intentRate)}>
                    <IntentRateTooltip>
                      <Flex align="center">
                        {performance.intentRate}%
                        <Icon
                          type={Icon.TYPES.TROPHY}
                          color={'gold'}
                          width={12}
                          className="ml-1"
                        />
                      </Flex>
                    </IntentRateTooltip>
                  </ConditionalDash>
                </Cell>
                <Cell>
                  <ConditionalDash
                    condition={Boolean(
                      performance.intentRate && performance.intentRate > 1
                    )}
                  >
                    <Icon
                      type={Icon.TYPES.CHECK_MARK}
                      color={Icon.COLORS.PRIMARY}
                      width={16}
                    />
                  </ConditionalDash>
                </Cell>
              </Row>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

const MobileEnabledTracksTable = ({ tracklist }: EnabledTracksTableProps) => (
  <>
    <div>
      <a
        style={{
          color: COLORS.GRAYSCALE.GRAY_MEDIUM,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginTop: '-28px'
        }}
        className="proton-Table__header"
        href={intercomPaths.intentRate}
        target="_blank"
      >
        Intent Rate
        <Icon type={Icon.TYPES.INFO} width={14} className="ml-1" />
      </a>
    </div>
    <Table headerProps={{ isHidden: true }}>
      <TableHeader>
        <Column>{''}</Column>
        <Column>{''}</Column>
      </TableHeader>
      <TableBody showLines={false}>
        {tracklist.map((rowData, index) => (
          <Row key={index + 1}>
            <Cell width={'100%'}>
              <div style={{ padding: '0.5rem' }}>
                <MobileTrackCellData trackCampaignData={rowData} />
              </div>
            </Cell>
            <Cell>
              <Flex align="center" justify="flex-end" className="mr-1">
                <ConditionalDash condition={Boolean(rowData.intentRate)}>
                  <>
                    {rowData.intentRate}%
                    <Icon
                      type={Icon.TYPES.TROPHY}
                      color={'gold'}
                      width={16}
                      className="ml-1"
                    />
                  </>
                </ConditionalDash>
              </Flex>
            </Cell>
          </Row>
        ))}
      </TableBody>
    </Table>
  </>
);

const EnabledTracks = () => {
  const { enabledTracklist: tracklist, discoveryMode } = useDiscoveryMode();
  const enabledTracklist = tracklist?.reverse();

  const isSmallScreen = useBreakpoint(
    useBreakpoint.BREAKPOINTS.SMALL,
    useBreakpoint.DIRECTIONS.DOWN
  );

  if (!tracklist || !enabledTracklist || enabledTracklist.length === 0) {
    return (
      <div className="mt-2" data-testid="DiscoveryMode-Enabled-Table">
        <TablePlaceholder>
          No tracks enabled for {discoveryMode?.runningCampaign.title}
        </TablePlaceholder>
      </div>
    );
  }

  return (
    <div data-testid="DiscoveryMode-Enabled-Table">
      {!isSmallScreen ? (
        <DesktopEnabledTracksTable tracklist={enabledTracklist} />
      ) : (
        <MobileEnabledTracksTable tracklist={enabledTracklist} />
      )}
    </div>
  );
};

export default EnabledTracks;
