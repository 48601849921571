import { RequestConfig } from './requestConfig';

declare global {
  interface Window {
    axiosPendingRequestCount?: number;
    axiosUnloadListener?: boolean;
  }
}
/*
By default, all patch, put, post, and delete calls will show a window unload event
if a user is leaving the page. To blacklist an api call with these methods, pass
"_unloadAlert_: false" along with the request header.
*/

const unloadAlertMethods = ['patch', 'put', 'post', 'delete'];
const displayUnloadAlert = (event: BeforeUnloadEvent) => {
  const alertMessage = 'Changes you made may not be saved.';
  event.returnValue = alertMessage;
  return alertMessage;
};

// check to see if we should display an alert if unloading while waiting for api response
const shouldAlertOnUnload = (requestConfig: RequestConfig) =>
  !!(
    requestConfig &&
    requestConfig.method &&
    unloadAlertMethods.includes(requestConfig.method.toLowerCase()) &&
    requestConfig._unloadAlert_ !== false
  );

export const initializePendingRequestListener = () => {
  window.axiosPendingRequestCount = 0;
  window.axiosUnloadListener = false;
};

export const handlePendingRequestIncrement = (config: RequestConfig) => {
  if (shouldAlertOnUnload(config)) {
    window.axiosPendingRequestCount = (window.axiosPendingRequestCount || 0) + 1;

    if (window.axiosPendingRequestCount >= 1 && !window.axiosUnloadListener) {
      window.addEventListener('beforeunload', displayUnloadAlert);
      window.axiosUnloadListener = true;
    }
  }
};

export const handlePendingRequestDecrement = (config: RequestConfig) => {
  if (shouldAlertOnUnload(config)) {
    window.axiosPendingRequestCount = (window.axiosPendingRequestCount || 0) - 1;
    // in case count gets screwed up... (shouldn't happen)
    if (window.axiosPendingRequestCount < 0) window.axiosPendingRequestCount = 0;

    if (window.axiosPendingRequestCount === 0 && window.axiosUnloadListener) {
      window.removeEventListener('beforeunload', displayUnloadAlert);
      window.axiosUnloadListener = false;
    }
  }
};
