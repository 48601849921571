import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Container from 'components/Layout/Container';

import { BREAKPOINTS, COLORS } from 'config/constants';

const StyledWrapper = styled.div<{ $background: string }>`
  background: ${({ $background }) => $background};
  min-height: 100vh;
  padding-bottom: 10rem;
  padding-top: 6rem;

  @media screen and (max-width: ${BREAKPOINTS.MD}px) {
    padding-top: 4rem;
  }

  @media screen and (max-width: ${BREAKPOINTS.SM}px) {
    padding-top: 3rem;
  }
`;

interface WrapperProps {
  background?: string;
  container?: boolean;
  children: ReactNode;
  'data-testid'?: string;
  size?: string;
  style?: React.CSSProperties;
}

const Wrapper = ({
  background = '#fff',
  container = true,
  children,
  'data-testid': dataTestId,
  size,
  style
}: WrapperProps) => (
  <StyledWrapper $background={background} style={style} data-testid={dataTestId}>
    {container ? <Container size={size}>{children}</Container> : children}
  </StyledWrapper>
);

Wrapper.SIZES = Container.SIZES;

Wrapper.BACKGROUNDS = {
  DEFAULT: '#ffffff',
  LIGHT: COLORS.GRAY_SUPER_LIGHT
};

export default Wrapper;
