import React from 'react';
import styled from 'styled-components';
import { Badge } from '@protonradio/proton-ui';
import Avatar from 'components/Avatar';
import TextHeader from 'components/TextHeader';

import { COLORS } from 'config/constants';

const StyledTextHeader = styled(TextHeader)`
  margin: 0;
`;

const StyledCheckbox = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid ${COLORS.BORDER};
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  transition:
    background-color 0.25s ease,
    border-color 0.25s ease;

  &:checked {
    background-color: ${COLORS.PRIMARY};
    border-color: ${COLORS.PRIMARY};
  }

  &:focus {
    outline: 2px solid ${COLORS.PRIMARY};
  }

  // check mark
  &:checked::after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
  }
`;

const SelectionItem = styled.label<{
  $isDisabled?: boolean;
  $isSelected?: boolean;
  $previouslyOptedIn?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;
  border-radius: 8px;
  cursor: ${props =>
    props.$previouslyOptedIn || props.$isDisabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => (props.$previouslyOptedIn || props.$isDisabled ? 0.6 : 1)};
  border: 1px solid ${props => (props.$isSelected ? COLORS.PRIMARY : COLORS.BORDER)};
  background-color: ${props =>
    props.$isSelected ? `${COLORS.PRIMARY}10` : 'transparent'};

  > :nth-child(1) {
    flex: 0 0 auto;
    outline: 2px solid ${props => (props.$isSelected ? COLORS.PRIMARY : 'transparent')};
    transition: outline 0.25s ease;
  }
  > :nth-child(2) {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  > :nth-child(3) {
    flex: 0 0 auto;
  }

  transition:
    background-color,
    border-color 0.25s ease;
`;

interface EntitySelectionItemProps {
  id: string;
  name: string;
  imageUrl: string;
  type: 'artist' | 'label';
  isSelected: boolean;
  isPreviouslyOptedIn: boolean;
  onSelectionChange: (id: string, isChecked: boolean) => void;
}

export const EntitySelectionItem: React.FC<EntitySelectionItemProps> = ({
  id,
  name,
  imageUrl,
  type,
  isSelected,
  isPreviouslyOptedIn,
  onSelectionChange
}) => {
  return (
    <SelectionItem
      $isSelected={isSelected || isPreviouslyOptedIn}
      $previouslyOptedIn={isPreviouslyOptedIn}
    >
      <Avatar imageUrl={imageUrl} size={Avatar.SIZES.LARGE} circle altTag={name} />

      <div>
        <StyledTextHeader type={TextHeader.TYPES.LARGE}>{name}</StyledTextHeader>

        {isPreviouslyOptedIn && <Badge variant="success">Opted in</Badge>}
      </div>

      <StyledCheckbox
        type="checkbox"
        name={type}
        value={id}
        checked={isSelected || isPreviouslyOptedIn}
        onChange={e => onSelectionChange(id, e.target.checked)}
      />
    </SelectionItem>
  );
};
