import Bugsnag from '@bugsnag/js';
import { devtoolsExchange } from '@urql/devtools';
import { authExchange } from '@urql/exchange-auth';
import ahoy from 'ahoy.js';
import { OperationDefinitionNode } from 'graphql';
import { getCookie } from 'react-use-cookie';
import {
  AnyVariables,
  OperationResult,
  createClient,
  fetchExchange,
  mapExchange
} from 'urql';
import { cacheExchange } from '@urql/exchange-graphcache';
import type { Cache, Variables } from '@urql/exchange-graphcache';

import { API_GRAPHQL_BASE, FINGERPRINT_COOKIE } from 'config/constants';
import { EVENTS, ProtonEventEmitter } from 'config/events';
import { clearAuthCookies, getCurrentAuthCookies, setAuthCookies } from 'helpers';
import { refreshToken } from './token';
import { DiscoveryModeConfigsQuery } from 'graphql/queries/discoveryMode';
import * as schema from 'gql/introspection.json';

const invalidateCachedData = (fieldName: string, args: Variables, cache: Cache) => {
  cache.invalidate({
    __typename: 'Query',
    fieldName: fieldName,
    arguments: args
  });
};

export const getClient = (_isLoggedIn: boolean | null, logout: () => void) => {
  return createClient({
    url: API_GRAPHQL_BASE,
    exchanges: [
      devtoolsExchange,
      cacheExchange({
        schema: schema,
        keys: {
          // Fields with non-reactive/static data don't need to be cached
          ArtistConfig: () => null,
          UserConfig: () => null,
          UserSetting: () => null,
          UserPolicy: () => null,
          UserRoles: () => null,
          ArtistImage: () => null,
          LabelConfig: () => null,
          ImageAsset: () => null,
          DiscoveryMode: () => null,
          PaginationInfo: () => null,
          PromoSubscriptionPagination: () => null,
          PromoPool: () => null
        },
        updates: {
          Mutation: {
            discoveryModeOptInOut: (_result, args, cache) => {
              const input = args.input as {
                artistId?: string;
                labelId?: string;
                enabled: boolean;
              };
              const entityId = input.artistId || input.labelId;
              const isArtist = Boolean(input.artistId);
              const entityKey = isArtist ? 'artists' : 'managedLabels';
              cache.updateQuery({ query: DiscoveryModeConfigsQuery }, data => {
                if (!data?.viewer) return data;
                return {
                  ...data,
                  viewer: {
                    ...data.viewer,
                    [entityKey]: data.viewer[entityKey].map(entity =>
                      entity.id === entityId
                        ? {
                            ...entity,
                            configs: {
                              ...entity.configs,
                              hasDiscoveryModeEnabled: input.enabled
                            }
                          }
                        : entity
                    )
                  }
                };
              });
              cache.invalidate('Query', 'discoveryMode');
            },
            userSettingUpdate: (result, _args, cache) => {
              const updatedSetting = (result.userSettingUpdate as any)?.userSetting;
              if (!updatedSetting) return;
              invalidateCachedData('viewer', {}, cache);
            },
            archiveAccessGrant: (_result, args, cache) =>
              invalidateCachedData('label', { id: args.labelId }, cache),
            archiveAccessRenew: (_result, args, cache) =>
              invalidateCachedData('label', { id: args.labelId }, cache),
            archiveAccessRevoke: (_result, args, cache) =>
              invalidateCachedData('label', { id: args.labelId }, cache)
          }
        }
      }),
      mapExchange({
        // leave breadcrumb of results if bugsnag error instance
        onResult: <Data>(result: OperationResult<Data, AnyVariables>) => {
          if (result.error) {
            const mfaError = result.error.graphQLErrors.find(
              ({ extensions }) => extensions.code === 'MFA_REQUIRED'
            );

            if (mfaError) {
              const requestToken = mfaError.extensions.token;
              ProtonEventEmitter.emit(EVENTS.MFA_REQUIRED, requestToken);
            }
          }

          const metadata = {
            query: result.operation.query.definitions.map(
              (def: OperationDefinitionNode) => def.name?.value
            ),
            variables: result.operation.variables,
            error: result.error,
            data: result.data
          };
          Bugsnag.leaveBreadcrumb('GraphQL Response', metadata, 'manual');
        }
      }),
      authExchange(utils => {
        const { jwt } = getCurrentAuthCookies();
        return Promise.resolve({
          addAuthToOperation(operation) {
            if (!jwt) return operation;
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${jwt}`
            });
          },
          didAuthError(error, _operation) {
            return error.graphQLErrors.some(
              e =>
                e.extensions.code === 'NOT_AUTHORIZED' &&
                e.message.includes('not logged in')
            );
          },
          async refreshAuth() {
            if (!jwt) return;
            const token = await refreshToken();
            if (token.jwt) {
              setAuthCookies(token.jwt, token.refresh_token || '');
            } else {
              clearAuthCookies();
              logout();
            }
          }
        });
      }),
      fetchExchange
    ],
    fetchOptions: () => {
      const ahoyHeaders = {};
      if (ahoy.getVisitToken()) {
        ahoyHeaders['Ahoy-Visit'] = ahoy.getVisitToken();
      }
      if (ahoy.getVisitorToken()) {
        ahoyHeaders['Ahoy-Visitor'] = ahoy.getVisitorToken();
      }
      return {
        headers: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          'x-fingerprint-visitor-id': getCookie(FINGERPRINT_COOKIE),
          ...ahoyHeaders
        }
      };
    }
  });
};
