import { Banner } from '@protonradio/proton-ui';
import { COLORS } from '@protonradio/proton-ui/constants';
import styled from 'styled-components';

/* Sidebar Components */

export const SidebarHeader = styled.h2`
  margin-top: 0.4rem;
  font-size: 2.2rem;
  color: ${COLORS.GRAYSCALE.GRAY_SUPER_DARK};
  font-weight: 600;
  letter-spacing: 0.1rem;
`;

export const SidebarSubheader = styled.h3`
  display: flex;
  align-items: baseline;
  align-self: baseline;
  margin: 0;
  font-size: 1.8rem;
  color: ${COLORS.GRAYSCALE.GRAY_DARK};
  font-weight: 500;
  letter-spacing: 0.1rem;
  line-height: 14px;
`;

export const CalendarBackground = styled.div`
  background-color: ${Banner.colors.danger.icon};
  height: 22px;
  width: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2px;
`;

/* Cart Components */

export const CartItem = styled.div`
  width: 100%;
  padding: 1rem;
  height: 80px;

  &:first-child {
    border: 1px solid ${COLORS.GRAYSCALE.BORDER};
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
  }

  &:nth-child(2) {
    border-top: 1px solid ${COLORS.GRAYSCALE.BORDER};
    border-bottom: 1px solid ${COLORS.GRAYSCALE.BORDER};
  }

  &:last-child {
    border: 1px solid ${COLORS.GRAYSCALE.BORDER};
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
  }
`;

export const RemoveButton = styled.button`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 20px;
  height: 20px;
  background-color: ${COLORS.GRAYSCALE.GRAY_MEDIUM_LIGHT};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  opacity: 0;
  transition:
    opacity,
    background-color,
    0.1s ease-in-out;

  &:hover {
    background-color: ${COLORS.GRAYSCALE.GRAY_MEDIUM};
  }
`;

export const CartImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &:hover #remove-button {
    opacity: 1;
  }
`;

export const CartImage = styled.img<{ $isRemoving?: boolean; }>`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  transition: opacity 100ms ease-in-out;
  opacity: ${props => (props.$isRemoving ? '50%' : '100%')};
`;
