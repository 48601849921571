import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { Select, SearchInput, ResponsiveSelect, COLORS } from '@protonradio/proton-ui';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import useCookie from 'react-use-cookie';
import { discoveryModeQueryParams, useDiscoveryMode } from './DiscoveryModeContext';
import { hideIntercomLauncher, showIntercomLauncher } from 'config/intercom';
import { BREAKPOINTS } from 'config/constants/styles';
import { DISCOVERY_MODE_BREAKPOINT } from './styles';

const PREFERRED_ENTITY_COOKIE = 'preferred_discovery_mode_entity';

const BaseSelectWrapper = styled.div`
  min-width: 200px;
  button {
    min-height: 40px;
    padding: 1rem 1.5rem;
  }

  @media (max-width: ${BREAKPOINTS.MD}px) {
    max-width: 200px;
  }
`;

const BaseSearchWrapper = styled.div`
  width: 100%;
  min-width: 320px;
  max-width: 480px;

  @media (max-width: ${BREAKPOINTS.MD}px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: ${DISCOVERY_MODE_BREAKPOINT}px) {
    padding: 1rem;
    flex-direction: column;
    background-color: ${COLORS.GRAYSCALE.GRAY_SUPER_LIGHT};
  }
`;

// Since cookies always save as strings, we need to check for invalid values
const hasValidEntity = (entity: string) =>
  entity && entity !== 'undefined' && entity !== 'null';

const DiscoveryModeFilterControls = () => {
  const {
    initialLoading,
    tracksLoading,
    entities,
    eligibleTracklist,
    enabledTracklist,
    isBeforeScheduling,
    isEnabledRoute,
    isAfterScheduling
  } = useDiscoveryMode();
  const { q: searchQuery, updateParams } = useUrlQueryParams(discoveryModeQueryParams);
  const defaultEntity = entities.find(e => e.isOptedIn)?.name || entities[0]?.name;
  const [preferredEntity, setPreferredEntity] = useCookie(
    PREFERRED_ENTITY_COOKIE,
    defaultEntity
  );

  const setSelectedEntity = useCallback((entity: string) => {
    if (!hasValidEntity(entity)) return;

    setPreferredEntity(entity, {
      SameSite: 'Lax',
      domain: 'protonradio.com'
    });
    updateParams({ e: entity }, 'replaceIn');
    updateParams({ q: undefined }, 'replaceIn');
  }, []);

  useEffect(() => {
    if (preferredEntity) {
      updateParams({ e: preferredEntity }, 'replaceIn');
      return;
    }

    setSelectedEntity(defaultEntity);
  }, [defaultEntity, entities, preferredEntity, setPreferredEntity, updateParams]);

  const options = entities.map(entity => ({
    label: `${entity.name}${entity.type === 'label' ? ' (Label)' : ''}`, //TODO: use ids in DM endpoint filter instead of names
    value: entity.name
  }));

  const isLoading = !searchQuery && (initialLoading || tracksLoading);
  const isDisabled =
    isLoading || (isBeforeScheduling && !isEnabledRoute) || isAfterScheduling;

  return (
    <Container>
      <BaseSearchWrapper>
        <SearchInput
          isDisabled={isDisabled}
          value={searchQuery as string}
          onChange={value => updateParams({ q: value }, 'replaceIn')}
          onClear={() => updateParams({ q: undefined }, 'replaceIn')}
          name="discovery-mode-track-search"
          placeholder={
            initialLoading
              ? 'Loading...'
              : isEnabledRoute
                ? `Search ${enabledTracklist.length} Enabled Tracks`
                : `Search ${eligibleTracklist.length} Eligible Tracks`
          }
        />
      </BaseSearchWrapper>
      <BaseSelectWrapper>
        <ResponsiveSelect.Menu
          isDisabled={isDisabled}
          name="discovery-mode-artist-filter"
          items={options}
          selectedKey={preferredEntity}
          onOpen={hideIntercomLauncher}
          onClose={showIntercomLauncher}
          onSelectionChange={setSelectedEntity}
        >
          {item => <Select.Option key={item.value}>{item.label}</Select.Option>}
        </ResponsiveSelect.Menu>
      </BaseSelectWrapper>
    </Container>
  );
};

export default DiscoveryModeFilterControls;
