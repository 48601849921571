import { DiscoveryModeEntity } from './DiscoveryModeContext';
import type {
  DiscoveryModeConfigsQueryQuery,
  DiscoveryModeTracksFieldsFragment
} from 'gql/graphql';

export const isEntityArtist = (
  entity: DiscoveryModeEntity | null | undefined
): entity is DiscoveryModeEntity & { type: 'artist' } => {
  return Boolean(entity && entity.type === 'artist');
};

const EMPTY_ARRAY: DiscoveryModeEntity[] = [];

/**
 * Maps track and enabled entities data into an array of DiscoveryModeEntity objects.
 */
export const mapDiscoveryModeTrackEntities = (
  track: DiscoveryModeTracksFieldsFragment['track'],
  enabledEntities: DiscoveryModeTracksFieldsFragment['entities']
): DiscoveryModeEntity[] => {
  const allArtists = [
    ...track.originalArtists,
    ...track.remixArtists,
    ...track.featuredArtists
  ]
    .filter(artist => enabledEntities.some(enabled => enabled.name === artist.name))
    .map(artist => ({
      id: artist.id,
      name: artist.name ?? '',
      isOptedIn: enabledEntities.some(
        enabled => enabled.name === artist.name && enabled.hasDiscoveryModeEnabled
      ),
      type: 'artist' as const,
      imageUrl: artist.image?.url || '',
      slug: enabledEntities.find(enabled => enabled.name === artist.name)?.slug || ''
    }));

  const label =
    track.release?.label &&
    enabledEntities.some(enabled => enabled.name === track.release?.label?.name)
      ? [
          {
            id: track.release.label.id,
            name: track.release.label.name ?? '',
            isOptedIn: enabledEntities.some(
              enabled =>
                enabled.name === track.release?.label?.name &&
                enabled.hasDiscoveryModeEnabled
            ),
            type: 'label' as const,
            imageUrl: track.release.label.logo?.url || '',
            slug:
              enabledEntities.find(enabled => enabled.name === track.release?.label?.name)
                ?.slug || ''
          }
        ]
      : [];

  return [...allArtists, ...label];
};

/**
 * Maps the DiscoveryModeConfigs viewer into an array of DiscoveryModeEntity objects.
 */
export const mapDiscoveryModeEntities = (
  viewer?: NonNullable<DiscoveryModeConfigsQueryQuery['viewer']> | null
) => {
  if (!viewer) return EMPTY_ARRAY;

  const artistEntities =
    viewer?.artists?.map(artist => ({
      id: artist.id,
      name: artist.name ?? '',
      isOptedIn: artist.configs?.hasDiscoveryModeEnabled ?? false,
      imageUrl: artist.image?.url || '',
      type: 'artist' as const,
      slug: `/artists/${artist.id}/${artist.name?.toLowerCase().replace(/\s+/g, '-')}`
    })) || EMPTY_ARRAY;
  const labelEntities =
    viewer?.managedLabels?.map(label => ({
      id: label.id,
      name: label.name ?? '',
      isOptedIn: label.configs?.hasDiscoveryModeEnabled ?? false,
      imageUrl: label.logo?.url || '',
      type: 'label' as const,
      slug: `/labels/${label.id}/${label.name?.toLowerCase().replace(/\s+/g, '-')}`
    })) || EMPTY_ARRAY;

  return [...artistEntities, ...labelEntities];
};

/*
 * Returns the ordinal suffix ('st', 'nd', 'rd', or 'th') for a given number.
 * @example
 * getOrdinalSuffix(1);  // returns 'st'
 * getOrdinalSuffix(22); // returns 'nd'
 * getOrdinalSuffix(3);  // returns 'rd'
 * getOrdinalSuffix(11); // returns 'th'
 */
export const getOrdinalSuffix = (n: number): string => {
  const lastDigit = n % 10;
  const lastTwoDigits = n % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return 'th';
  }

  switch (lastDigit) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

/**
 * Formats a date into a string with the format: "Weekday, Month Day(st/nd/rd/th)".
 * @example
 * formatDate(new Date('2023-05-15')); // returns "Monday, May 15th"
 * formatDate('2023-12-22');           // returns "Friday, December 22nd"
 */
export const formatDate = (date: Date): string => {
  const dateObj = new Date(date);
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: 'UTC',
    weekday: 'long',
    month: 'long',
    day: 'numeric'
  });
  const formattedDate = formatter.format(dateObj);
  const day = dateObj.getUTCDate();
  const suffix = getOrdinalSuffix(day);
  return formattedDate.replace(/\d+$/, `${day}${suffix}`);
};
