import React, { CSSProperties, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { BREAKPOINTS, MAX_WIDTH } from 'config/constants';

export const CONTAINER_PADDING = {
  LARGE: 3,
  MEDIUM: 2,
  SMALL: 0.8
} as const;

// In the event that you need to offset the container padding, use the following styled css:
export const InverseContainerCss = css`
  margin-right: -${CONTAINER_PADDING.LARGE}rem;
  margin-left: -${CONTAINER_PADDING.LARGE}rem;

  @media screen and (max-width: ${BREAKPOINTS.LARGE}px) {
    margin-right: -${CONTAINER_PADDING.MEDIUM}rem;
    margin-left: -${CONTAINER_PADDING.MEDIUM}rem;
  }

  @media screen and (max-width: ${BREAKPOINTS.SM}px) {
    margin-right: -${CONTAINER_PADDING.SMALL}rem;
    margin-left: -${CONTAINER_PADDING.SMALL}rem;
  }
`;

export const ContainerPaddingCss = css`
  padding-right: ${CONTAINER_PADDING.LARGE}rem;
  padding-left: ${CONTAINER_PADDING.LARGE}rem;

  @media screen and (max-width: ${BREAKPOINTS.LARGE}px) {
    padding-right: ${CONTAINER_PADDING.MEDIUM}rem;
    padding-left: ${CONTAINER_PADDING.MEDIUM}rem;
  }

  @media screen and (max-width: ${BREAKPOINTS.SM}px) {
    padding-right: ${CONTAINER_PADDING.SMALL}rem;
    padding-left: ${CONTAINER_PADDING.SMALL}rem;
  }
`;

const StyledContainer = styled.div<{
  $background?: CSSProperties['background'];
  $size?: string;
  $overflow?: CSSProperties['overflow'];
}>`
  background: ${({ $background }) => $background};
  margin: 0 auto;
  max-width: ${({ $size }) => $size};
  overflow: ${({ $overflow }) => $overflow};
  padding: 0 ${CONTAINER_PADDING.LARGE}rem;
  position: relative;
  width: 100%;

  @media screen and (max-width: ${BREAKPOINTS.LARGE}px) {
    padding-right: ${CONTAINER_PADDING.MEDIUM}rem;
    padding-left: ${CONTAINER_PADDING.MEDIUM}rem;
  }

  @media screen and (max-width: ${BREAKPOINTS.SM}px) {
    padding-right: ${CONTAINER_PADDING.SMALL}rem;
    padding-left: ${CONTAINER_PADDING.SMALL}rem;
  }

  & > div {
    width: 100%;
  }
`;

interface ContainerProps {
  as?: string;
  children: ReactNode;
  className?: string;
  background?: CSSProperties['background'];
  'data-testid'?: string;
  overflow?: CSSProperties['overflow'];
  size?: string;
  style?: CSSProperties;
}

/**
 * [Container] - General purpose layout component for the outer-most wrapper establishing
 * consistent padding on the left-right of page across desktop to mobile.
 *
 * @param {string} as - Change the DOM tag. Use-case should be uncommon.
 * @param {object} background - Set a background color to the Container.
 * @param {string} overflow - Manually set an overflow value on div
 * @param {object} size - Pre-defined max-widths at which point to center the content.
 *                        Most pages currently use `EXTRA_LARGE`, with the exception of `LARGE`
 *                        on the Account pages. Other sizes to be refined.
 */

const Container = ({
  as,
  background,
  children,
  className,
  'data-testid': dataTestId,
  overflow,
  size = SIZES.FULL,
  style
}: ContainerProps) => (
  <StyledContainer
    as={as}
    className={className}
    data-testid={dataTestId}
    $overflow={overflow}
    $background={background}
    $size={size}
    style={style}
  >
    {children}
  </StyledContainer>
);

const SIZES = {
  SMALL: '60rem',
  MEDIUM: '100rem',
  LARGE: '123rem',
  EXTRA_LARGE: `${MAX_WIDTH}px`,
  FULL: '100%'
} as const;

Container.SIZES = SIZES;

export default Container;
