import React, { ReactNode, useContext, useEffect } from 'react';
import { CombinedError, useQuery } from 'urql';

import { DownloadFileType, DownloadLocation } from 'config/constants';
import { graphql } from 'gql';
import { GetCurrentUserQuery, UserSettingTypeEnum } from 'gql/graphql';
import { showAlert } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from './redux';

export const CurrentUserQuery = graphql(`
  query getCurrentUser {
    viewer {
      id
      username
      token
      managedLabels {
        id
        name
      }
      privacyPolicyConsents {
        id
        version
        consentedAt
      }
      roles {
        admin
        artist
        curator
        labelManager
        showHost
      }
      permissions {
        accessPromoPool
        proUser
      }
      settings {
        enabled
        settingType
        value
      }
    }
  }
`);

// The `Viewer` type is the type as it is received from the GraphQL API. We
// transform the format of the settings object and return the modified version
// from the `useCurrentUser` hook as the `User` type.
type Viewer = NonNullable<GetCurrentUserQuery['viewer']>;
type UserSetting<T extends UserSettingTypeEnum, TValue = string> = {
  settingType: T;
  enabled: boolean;
  value: TValue;
};

export interface User extends Omit<Viewer, 'settings'> {
  settings: {
    [T in UserSettingTypeEnum]: T extends 'DOWNLOAD_FORMAT'
      ? UserSetting<T, DownloadFileType>
      : T extends 'DOWNLOAD_LOCATION'
        ? UserSetting<T, DownloadLocation>
        : UserSetting<T>;
  };
}

export interface CurrentUserContext {
  user: User;
  fetching: boolean;
  error: CombinedError | undefined;
}

const CACHE_KEY = 'CURRENT_USER';

const NO_USER: User = {
  id: '',
  username: '',
  token: '',
  roles: {
    admin: false,
    artist: false,
    curator: false,
    labelManager: false,
    showHost: false
  },
  managedLabels: [],
  privacyPolicyConsents: [],
  permissions: {
    accessPromoPool: false,
    proUser: false
  },
  settings: {
    AUTO_DOWNLOAD: {
      settingType: UserSettingTypeEnum.AutoDownload,
      value: '',
      enabled: false
    },
    DOWNLOAD_FORMAT: {
      settingType: UserSettingTypeEnum.DownloadFormat,
      value: 'mp3',
      enabled: false
    },
    DOWNLOAD_LOCATION: {
      settingType: UserSettingTypeEnum.DownloadLocation,
      value: 'local',
      enabled: false
    },
    PROMO_LABEL_INVITE_NOTIFICATION: {
      settingType: UserSettingTypeEnum.PromoLabelInviteNotification,
      value: '',
      enabled: false
    },
    PROMO_TRACK_NOTIFICATION: {
      settingType: UserSettingTypeEnum.PromoTrackNotification,
      value: '',
      enabled: false
    },
    RELEASE_LINK_ONBOARDING_SEEN: {
      settingType: UserSettingTypeEnum.ReleaseLinkOnboardingSeen,
      value: '',
      enabled: false
    },
    DISCOVERY_MODE_ONBOARDING_SEEN: {
      settingType: UserSettingTypeEnum.DiscoveryModeOnboardingSeen,
      value: '',
      enabled: false
    }
  }
};

function buildSettingsIndex({ settings }: Viewer) {
  return Object.fromEntries(
    settings.map(setting => [setting.settingType, setting])
  ) as User['settings'];
}

function transformUser(viewer: Viewer): User {
  return {
    ...viewer,
    settings: buildSettingsIndex(viewer)
  };
}

export const UserContext = React.createContext<CurrentUserContext>({
  user: NO_USER,
  fetching: false,
  error: undefined
});

export const CurrentUserProvider = (props: { children: ReactNode }) => {
  // Always fetch new user when jwt changes (login/logout)
  const token = useAppSelector(state => state.token.jwt);
  const stringifiedUser = token && window.localStorage.getItem(CACHE_KEY);
  const cachedUser = stringifiedUser ? (JSON.parse(stringifiedUser) as User) : null;
  const dispatch = useAppDispatch();

  // We always fetch the user, but if there's a cached user, we'll use that first
  const [{ data, error, fetching }] = useQuery({
    query: CurrentUserQuery,
    requestPolicy: 'cache-and-network'
  });

  const fetchedUser = data?.viewer && transformUser(data.viewer);
  const user = fetchedUser || cachedUser || NO_USER;

  useEffect(() => {
    if (fetchedUser) {
      window.localStorage.setItem(CACHE_KEY, JSON.stringify(fetchedUser));
    }

    // If the user logged out, clear the user from local storage
    if (!fetchedUser && !token) {
      window.localStorage.removeItem(CACHE_KEY);
    }
  }, [fetchedUser, token]);

  if (error) {
    dispatch(showAlert({ message: 'Error loading user, please refresh' }));
  }

  return (
    <UserContext.Provider
      value={{
        user,
        fetching,
        error
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

const useCurrentUser = (): CurrentUserContext => useContext(UserContext);

export default useCurrentUser;
