import React from 'react';

import { Badge } from '@protonradio/proton-ui';
import { COLORS } from '@protonradio/proton-ui/constants';
import Avatar from 'components/Avatar';
import CommaList from 'components/CommaList';
import TextHeader from 'components/TextHeader';

import type { DiscoveryModeTracksFieldsFragment } from 'gql/graphql';
import styled from 'styled-components';

const StyledTrackMeta = styled.span`
  display: flex;
  flex: 1 1 auto;
  padding: 0.8rem;
  border-radius: 0.4rem;
  background: ${COLORS.GRAYSCALE.GRAY_SUPER_LIGHT};
`;

const StyledTrackInfoMeta = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.8rem;
  width: 0;
  flex: 1;
`;

const StyledBottomMeta = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 0.2rem;
  width: max-content;
  scale: 0.9;
  transform-origin: left center;
`;

interface InviteTrackTileProps {
  track: DiscoveryModeTracksFieldsFragment['track'];
  remainingOptIns: number;
}

const InviteTrackTile = ({
  track,
  remainingOptIns,
}: InviteTrackTileProps) => {
  const { title, version, originalArtists, release } = track;
  const titleString = `${title}${version ? ` (${version})` : ''}`;

  return (
    <StyledTrackMeta>
      {release?.coverArt?.url && (
        <Avatar
          altTag="Release cover art"
          imageUrl={encodeURI(release.coverArt.url)}
          size={Avatar.SIZES.LARGE}
          lazy={false}
        />
      )}

      <StyledTrackInfoMeta id="track-meta">
        <TextHeader
          type={TextHeader.TYPES.MEDIUM}
          weight={TextHeader.WEIGHTS.NORMAL}
          style={{ lineHeight: 1.1, marginBottom: '2px' }}
          truncate
        >
          {titleString}
        </TextHeader>

        <div>
          <CommaList data={originalArtists} attribute="name" />
        </div>

        <StyledBottomMeta>
          <Badge variant="warning">{remainingOptIns} Not Opted In</Badge>
        </StyledBottomMeta>
      </StyledTrackInfoMeta>
    </StyledTrackMeta>
  );
};

export default InviteTrackTile;
