import React from 'react';
import { useDispatch } from 'react-redux';

import { displayAlertError } from 'helpers';

import StarRating, { StarRatingProps } from 'components/StarRating';
import {
  selectPromoTrackReactionArtistId,
  selectPromoTrackReaction,
  PromoTrackReaction
} from 'redux/selectors/promoReleases';
import { updateTrackReaction as updateTrackReactionAction } from 'redux/actions/promoReleases';
import { downloadTrack as downloadTrackAction } from 'redux/actions/tracks';
import useCurrentUser from '../hooks/useCurrentUser';
import { trackDownloadConfig } from '../helpers/tracks';
import { useAppSelector } from 'hooks/redux';
import { selectUser } from 'redux/selectors';
import { DownloadFileType, USER_SETTINGS } from 'config/constants';
import { Audio } from 'helpers/tracks';

/**
 * [PromoTrackStarRating] - handles display of rating from provided track as
 * well as provides necessary change handlers
 */

interface PromoTrackStarRatingProps extends Omit<StarRatingProps, 'rating' | 'onChange'> {
  track: Audio;
  format?: DownloadFileType;
}

const PromoTrackStarRating = ({
  track,
  format,
  ...starRatingProps
}: PromoTrackStarRatingProps) => {
  const { user: currentUser } = useCurrentUser();
  const dispatch = useDispatch();

  const user = useAppSelector(selectUser);
  const { reaction, artistId } = useAppSelector(state => ({
    user: state.user,
    reaction: selectPromoTrackReaction(state, track.id) || ({} as PromoTrackReaction),
    artistId: selectPromoTrackReactionArtistId(state, track.release.id, track.id)
  }));

  return (
    <StarRating
      {...starRatingProps}
      rating={reaction.rating}
      onChange={rating => {
        const updateArgs = {
          track,
          artistId,
          rating
        };

        const tryAutoDownload = () => {
          const autoDownloadSetting = currentUser.settings[USER_SETTINGS.AUTO_DOWNLOAD];
          if (autoDownloadSetting.enabled && rating >= autoDownloadSetting.value) {
            return dispatch(
              downloadTrackAction.call(
                trackDownloadConfig(currentUser, { user, track, format })
              )
            );
          }
        };

        dispatch(
          updateTrackReactionAction.call(updateArgs, {
            onSuccess: tryAutoDownload,
            onError: displayAlertError,
            optimistic: true
          })
        );
      }}
    />
  );
};

PromoTrackStarRating.SIZE = StarRating.SIZE;

export default PromoTrackStarRating;
