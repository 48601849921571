import { graphql } from 'gql';

export const SetOnboardingSeenMutation = graphql(`
  mutation discoveryModeOnboardingSeenUpdate {
    discoveryModeOnboardingSeenUpdate(input: {}) {
      errors
      errorDetails {
        message
        path
      }
    }
  }
`);

export const ToggleDiscoveryModeMutation = graphql(`
  mutation discoveryModeOptInOut($artistId: ID, $labelId: ID, $enabled: Boolean!) {
    discoveryModeOptInOut(
      input: { artistId: $artistId, labelId: $labelId, enabled: $enabled }
    ) {
      enabled
      errors
      errorDetails {
        message
        path
      }
    }
  }
`);

export const EnableDisableTrackMutation = graphql(`
  mutation EnabledDisableTrack($input: DiscoveryModeEnableDisableTrackInput!) {
    discoveryModeEnableDisableTrack(input: $input) {
      discoveryModeTrack {
        id
        enabled
        enabledBy {
          id
          name
        }
        track {
          id
          title
          version
          originalArtists {
            id
            name
          }
          release {
            id
            coverArt(size: SMALL) {
              url
            }
          }
        }
      }
      errors
      errorDetails {
        message
        path
      }
    }
  }
`);
