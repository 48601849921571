import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { COLORS } from 'config/constants';

import Icon from 'components/Icon';
import Button from '../Button';

interface EllipsisButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  dark?: boolean;
  isActive?: boolean;
  [key: string]: any;
}

/**
 * [EllipsisButton] button with "•••" used to toggle dropdowns and action menus (see EllipsisButton.Mobile)
 *
 * @param {bool} [dark] - pass true if using over a dark background
 */

const EllipsisButton = React.forwardRef(
  ({ className, onClick, children, dark, isActive, disabled, ...rest }, ref) => {
    const _class = classNames('EllipsisButton', className, {
      'EllipsisButton--dark': dark,
      'EllipsisButton--display-only': !onClick || disabled,
      'EllipsisButton--isActive': isActive
    });

    return (
      <Button
        disabled={disabled}
        onClick={onClick}
        className={_class}
        color={Button.COLORS.TRANSPARENT}
        size={Button.SIZES.MINIMAL}
        innerRef={ref}
        flex
        {...rest}
      >
        {children}
        <Icon
          type={Icon.TYPES.MORE}
          width={12}
          color={dark ? COLORS.GRAY_MEDIUM : COLORS.GRAY_DARK}
        />
      </Button>
    );
  }
) as EllipsisButtonComponent;

const StyledButton = styled(Button)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 4rem;

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`;

interface EllipsisButtonMobileProps extends React.HTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  dark?: boolean;
  className?: string;
}

type EllipsisButtonComponent = React.ForwardRefExoticComponent<EllipsisButtonProps> &
  React.RefAttributes<HTMLButtonElement> & {
    Mobile: React.FC<EllipsisButtonMobileProps>;
  };

/**
 * [EllipsisButton.Mobile] - similar to EllipsisButton, but with higher "slop" for mobile
 */

EllipsisButton.Mobile = ({ onClick, dark, className }) => (
  <StyledButton
    onClick={onClick}
    className={className}
    color={Button.COLORS.TRANSPARENT}
    flex
  >
    <Icon
      type={Icon.TYPES.MORE}
      width={12}
      color={dark ? COLORS.GRAY_MEDIUM : COLORS.GRAY_DARK}
    />
  </StyledButton>
);

export default EllipsisButton;
