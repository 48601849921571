import { QueryParamConfig, useQueryParams, withDefault } from 'use-query-params';

type ParamConfig<T> = {
  type: QueryParamConfig<T>;
  default: T;
};

type UrlParamsConfig<Params> = {
  [K in keyof Params]: ParamConfig<Params[K]>;
};

type UrlParamsConfigDefaultValues<T extends UrlParamsConfig<any>> = {
  [K in keyof T]: T[K]['default'];
};

/**
 * Infers the type of a query parameter from a `QueryParamConfig`.
 *
 * This utility type takes a `QueryParamConfig` type and extracts the type of the query parameter it represents.
 *
 * @template T - The `QueryParamConfig` type from which to infer the query parameter type.
 * @returns The inferred type of the query parameter.
 */
type InferParamType<T> = T extends QueryParamConfig<infer U> ? U : never;

/**
 * Infers the parameter types from a given URL parameters configuration.
 *
 * This utility type takes a configuration object `T` that extends `UrlParamsConfig<any>`
 * and produces a new type where each key in `T` maps to the inferred type of the parameter
 * specified by `T[K]['type']`.
 *
 * @template T - The URL parameters configuration object.
 *
 * @example
 * ```typescript
 * type Config = {
 *   userId: { type: 'number', default: 0 },
 *   userName: { type: 'string', default: '' },
 * };
 *
 * type Params = InferParamsFromConfig<Config>;
 * // Resulting type:
 * // {
 * //   userId: number;
 * //   userName: string;
 * // }
 * ```
 */
type InferParamsFromConfig<T extends UrlParamsConfig<any>> = {
  [K in keyof T]: InferParamType<T[K]['type']>;
};

/**
 * A utility type that maps each key in the `Params` type to a `QueryParamConfig` type.
 *
 * @template Params - An object type where each key represents a query parameter name and its value represents the type of the query parameter.
 */
type AsQpcMap<Params> = {
  [N in keyof Params]: QueryParamConfig<Params[N]>;
};

export type UpdateQueryParams<Config extends UrlParamsConfig<any>> = (
  newParams: Partial<UrlParamsConfigDefaultValues<Config>>,
  updateType?: 'pushIn' | 'replaceIn'
) => void;

type UseUrlParamsResult<Config extends UrlParamsConfig<any>> =
  UrlParamsConfigDefaultValues<Config> & {
    updateParams: UpdateQueryParams<Config>;
  };

/**
 * A custom hook that manages URL query parameters based on a given configuration.
 *
 * @template Config - The configuration type for URL parameters.
 *
 * @param {Config} config - The configuration object that defines the URL parameters and their default values.
 *
 * @returns {UseUrlParamsResult<Config>} An object containing the current URL query parameters and a function to update them.
 */
function useUrlQueryParams<Config extends UrlParamsConfig<any>>(
  config: Config
): UseUrlParamsResult<Config> {
  const queryConfig = Object.fromEntries(
    Object.entries(config).map(([key, value]) => [
      key,
      withDefault(value.type, value.default)
    ])
  ) as AsQpcMap<UrlParamsConfigDefaultValues<Config>>;

  const [urlQueries, setUrlQueries] = useQueryParams(queryConfig);

  const typedUrlQueries = urlQueries as UseUrlParamsResult<InferParamsFromConfig<Config>>;

  return {
    ...typedUrlQueries,
    updateParams: setUrlQueries
  };
}

export default useUrlQueryParams;
