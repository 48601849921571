import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { Button, TextEmphasis } from '@protonradio/proton-ui';
import Flex from 'components/Flex';
import TextHeader from 'components/TextHeader';
import { Colon, CountdownTimerContainer, TablePlaceholder, TimeLeft } from './styles';

import { intercomPaths } from './DiscoveryMode';
import { isNotificationsEnabled } from './featureFlags';
import { formatDate } from './helpers';

const StyledTextHeader = styled(TextHeader)`
  text-align: center;
  margin: 1rem 0;
`;

type RemainingTime = {
  days: string;
  hours: string;
  minutes: string;
};

const DEFAULT_REMAINING_TIME: RemainingTime = {
  days: '00',
  hours: '00',
  minutes: '00'
};

export const calculateRemainingTime = (targetDate?: string): RemainingTime => {
  if (!targetDate) return DEFAULT_REMAINING_TIME;

  const target = targetDate.split('Z')[0];

  const difference = new Date(target).getTime() - new Date().getTime();

  const addLeadingZero = (value: number): string => {
    return value < 10 ? `0${value}` : value.toString();
  };

  if (difference > 0) {
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    return {
      days: addLeadingZero(days),
      hours: addLeadingZero(hours),
      minutes: addLeadingZero(minutes)
    };
  }

  return DEFAULT_REMAINING_TIME;
};

const CountdownTimer = ({
  title,
  schedulingStartsAt
}: {
  title?: string;
  schedulingStartsAt?: string;
}) => {
  const schedulingStartDate = useMemo(
    () => schedulingStartsAt ?? undefined,
    [schedulingStartsAt]
  );
  const [remaining, setRemaining] = useState<RemainingTime>(
    calculateRemainingTime(schedulingStartDate)
  );

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setRemaining(calculateRemainingTime(schedulingStartDate));
    }, 60000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [schedulingStartsAt]);

  const subtitleHeaderProps = {
    type: TextHeader.TYPES.MEDIUM,
    weight: TextHeader.WEIGHTS.LIGHT
  };

  return (
    <div data-testid={CountdownTimerTestIds.COUNTDOWN}>
      <TablePlaceholder>
        <CountdownTimerContainer>
          <StyledTextHeader type={TextHeader.TYPES.XLARGE}>
            <TextEmphasis>{title} Campaign</TextEmphasis>
          </StyledTextHeader>

          {schedulingStartDate && (
            <StyledTextHeader
              type={TextHeader.TYPES.LARGE}
              weight={TextHeader.WEIGHTS.NORMAL}
              colorLinks
            >
              <a href={intercomPaths.eligible} target="_blank" rel="noreferrer">
                Eligible tracks
              </a>{' '}
              will be available on {formatDate(new Date(schedulingStartDate))}.
            </StyledTextHeader>
          )}

          <Flex align="baseline" justify="center" className="mt-2 mb-2">
            <div>
              <TimeLeft>{remaining.days}</TimeLeft>
              <StyledTextHeader {...subtitleHeaderProps}>days</StyledTextHeader>
            </div>

            <Colon>:</Colon>

            <div>
              <TimeLeft>{remaining.hours}</TimeLeft>
              <StyledTextHeader {...subtitleHeaderProps}>hours</StyledTextHeader>
            </div>

            <Colon>:</Colon>

            <div>
              <TimeLeft>{remaining.minutes}</TimeLeft>
              <StyledTextHeader {...subtitleHeaderProps}>minutes</StyledTextHeader>
            </div>
          </Flex>
          {isNotificationsEnabled && (
            <StyledTextHeader {...subtitleHeaderProps} className="mb-3">
              <i>We'll notify you when your tracks are ready!</i>
            </StyledTextHeader>
          )}

          <Button variant="secondary" to={intercomPaths.faq}>
            Learn more
          </Button>
        </CountdownTimerContainer>
      </TablePlaceholder>
    </div>
  );
};

export const CountdownTimerTestIds = {
  COUNTDOWN: 'DiscoveryMode-CountdownTimer'
};

export default CountdownTimer;
