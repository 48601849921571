import React from 'react';
import styled from 'styled-components';

import { BREAKPOINTS } from 'config/constants';

const generateResponsiveColumns = columns =>
  Object.keys(columns).reduce((accum, value) => {
    if (value === 'default') {
      return accum.concat(`grid-template-columns: ${columns[value]}; `);
    }

    return accum.concat(`@media screen and (max-width: ${
      BREAKPOINTS[value.toUpperCase()]
    }px) {
      grid-template-columns: ${columns[value]};
    } `);
  }, '');

const StyledGrid = styled.div<{
  $align?: string;
  $columns: string | object;
  $gap?: string;
}>`
  align-items: ${({ $align }) => $align};
  display: grid;
  gap: ${({ $gap }) => $gap || '3rem'};

  ${({ $columns }) => {
    if (typeof $columns === 'string') {
      return `grid-template-columns: ${$columns};`;
    }

    return generateResponsiveColumns($columns);
  }}
`;

// TODO:
// 1. Make `gap` parameter responsive like `columns`?
// 2. Automatically sort breakpoints in objects if out of order.

/**
 * [Grid] - General purpose layout component CSS Grids
 *
 * @param {string} align - Custom value for CSS align-items
 * @param {string | object} columns - String or object of columns for various breakpoints.
 *               Breakpoints must currently be in order of default->lg->md->sm
 *              String Examples:
 *                  '1fr 100px 1fr'
 *                  '1000px 2fr 3rem'
 *              Object Examples:
 *                  columns={{ default: '1fr 100px 1fr', lg: '2fr 1fr', sm: '1fr' }}
 *                  columns={{ default: '1fr', md: '3fr 7fr', sm: '1fr 1fr' }}
 * @param {string} gap - Value for CSS grid `gap`.
 */

interface GridProps {
  align?: string;
  children: React.ReactNode;
  columns: string | object;
  'data-testid'?: string;
  gap?: string;
}

const Grid = ({
  align,
  children,
  columns,
  'data-testid': dataTestId,
  gap
}: GridProps) => (
  <StyledGrid $align={align} $columns={columns} $gap={gap} data-testid={dataTestId}>
    {children}
  </StyledGrid>
);

export default Grid;
