import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import TextHeader from 'components/TextHeader';
import { TombstoneText } from 'components/Tombstone';
import Icon from 'components/Icon';

import { getTrackArtistsByRole } from 'helpers';
import { ARTIST_ROLES } from 'config/constants';
import { MEDIUM_BREAK_POINT } from 'config/constants/styles';
import CommaList from 'components/CommaList';

export const StyledBottomMeta = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;

  > * {
    margin-bottom: 0;
  }
`;

export const StyledTrackMeta = styled.span`
  > * {
    margin-bottom: 0;
  }

  display: flex;
  flex: 1 1 auto;
  width: 0; // force flex to take full width
  flex-direction: column;
  padding-left: 0.8rem;

  ${({ loading }) =>
    loading &&
    `
      margin: 0.4rem 0.2rem;
  `};
`;

const StyledIconWrapper = styled.div`
  margin-left: 0.4rem;
  position: relative;
  top: 0.1rem;

  @media screen and (max-width: ${MEDIUM_BREAK_POINT}px) {
    display: inline;
  }
`;

const TrackMobileTileMeta = ({ track, showCompile }) => {
  const { title, version, label, artists, no_compile: noCompile } = track;
  const ogArtists = getTrackArtistsByRole(artists, ARTIST_ROLES.ORIGINAL);
  const titleString = `${title}${version ? ` (${version})` : ''}`;
  return (
    <StyledTrackMeta>
      <TextHeader
        type={TextHeader.TYPES.MEDIUM}
        weight={TextHeader.WEIGHTS.NORMAL}
        style={{ lineHeight: 1.1, marginBottom: '2px' }}
        truncate
      >
        {titleString}

        {showCompile && noCompile && (
          <StyledIconWrapper>
            <Icon type={Icon.TYPES.LOCK} color={Icon.COLORS.DARK} width={15} />
          </StyledIconWrapper>
        )}
      </TextHeader>

      <div>
        <CommaList data={ogArtists} attribute="name" />
      </div>

      <StyledBottomMeta>
        <TextHeader
          type={TextHeader.TYPES.XSMALL}
          color={TextHeader.COLORS.SECONDARY}
          uppercase
          truncate
        >
          {label.name}
        </TextHeader>
      </StyledBottomMeta>
    </StyledTrackMeta>
  );
};

TrackMobileTileMeta.Tombstone = () => (
  <StyledTrackMeta loading="true">
    <TombstoneText
      size={TombstoneText.SIZES.MEDIUM}
      color={TombstoneText.COLORS.LIGHT}
      style={{ width: '30rem' }}
    />
    <TombstoneText
      size={TombstoneText.SIZES.SMALL}
      color={TombstoneText.COLORS.LIGHT}
      style={{ width: '5rem', marginTop: '0.3rem' }}
    />
  </StyledTrackMeta>
);

TrackMobileTileMeta.propTypes = {
  track: PropTypes.shape({
    title: PropTypes.string,
    version: PropTypes.string,
    no_compile: PropTypes.bool,
    label: PropTypes.shape({
      name: PropTypes.string
    }),
    artists: PropTypes.arrayOf(PropTypes.shape())
  }).isRequired,
  showCompile: PropTypes.bool
};

export default TrackMobileTileMeta;
