import React, { useState } from 'react';

import {
  Cell,
  COLORS,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
  TooltipTrigger
} from '@protonradio/proton-ui';
import { useDiscoveryMode } from '../DiscoveryModeContext';
import { isEntityArtist } from '../helpers';

import type { DiscoveryModeTracksFieldsFragment } from 'gql/graphql';

import CommaList from 'components/CommaList';
import Flex from 'components/Flex';
import { TablePlaceholder, TableWrapper } from '../styles';
import Icon from 'components/Icon';
import {
  ConditionalDash,
  IntentRateTooltip,
  MobileTrackCellData,
  TrackCellData,
  TrackScheduleButton
} from './TableComponents';
import InviteModal from '../Modal/Invite/InviteModal';
import EllipsisButton from 'components/Button/EllipsisButton/EllipsisButton';
import SchedulingActionMenu from '../Scheduling/SchedulingActionMenu';
import { Link } from 'react-router-dom';
import { ICON_TYPES } from 'components/Icon/iconTypes';

interface EligibleTracksTableProps {
  isDisabled: boolean;
  tracklist: DiscoveryModeTracksFieldsFragment[];
  campaign: {
    title: string;
    id: string;
  };
  setInviteModalTrackIndex: (index: number | undefined) => void;
}

const DesktopEligibleTracksTable = ({
  tracklist,
  campaign,
  isDisabled,
  setInviteModalTrackIndex
}: EligibleTracksTableProps) => {
  const {
    scheduledQueue: { toggleTrack },
    selectedEntity
  } = useDiscoveryMode();

  const isArtist = isEntityArtist(selectedEntity);

  return (
    <TableWrapper $isDisabled={isDisabled} className="Table mt-0">
      <Table>
        <TableHeader>
          <Column>Track</Column>
          <Column>Label</Column>
          <Column>Remix Artists</Column>
          <Column>
            <Flex align="center">
              Intent Rate{' '}
              <IntentRateTooltip>
                <Icon
                  type={Icon.TYPES.INFO}
                  width={14}
                  className="ml-1"
                  color={COLORS.GRAYSCALE.GRAY_MEDIUM}
                />
              </IntentRateTooltip>
            </Flex>
          </Column>
          <Column>Scheduled for {campaign.title}</Column>
          <Column>Scheduled by</Column>
          <Column>{''}</Column>
        </TableHeader>
        <TableBody showLines={false}>
          {tracklist?.map((rowData, index) => {
            const performance = rowData;
            const track = performance.track;

            return (
              <Row key={index + 1} data-testid="row">
                <Cell>
                  <TrackCellData trackCampaignData={rowData} />
                </Cell>
                <Cell>
                  <Link to={`${track.release?.label?.slug}/tracks`}>
                    {track.release?.label?.name}
                  </Link>
                </Cell>
                <Cell>
                  <ConditionalDash condition={Boolean(track.remixArtists.length)}>
                    <CommaList
                      data={track.remixArtists.map(a => ({ name: a.name, slug: a.slug }))}
                      urlAttribute={artist => artist.slug}
                      attribute="name"
                    />
                  </ConditionalDash>
                </Cell>
                <Cell>
                  <ConditionalDash condition={Boolean(performance.intentRate)}>
                    <IntentRateTooltip>
                      <Flex align="center">
                        {performance.intentRate}%
                        <Icon
                          type={Icon.TYPES.TROPHY}
                          color={'gold'}
                          width={12}
                          className="ml-1"
                        />
                      </Flex>
                    </IntentRateTooltip>
                  </ConditionalDash>
                </Cell>
                <Cell>
                  <ConditionalDash condition={Boolean(performance.enabled)}>
                    <Icon
                      type={Icon.TYPES.CHECK_MARK}
                      color={Icon.COLORS.PRIMARY}
                      width={16}
                    />
                  </ConditionalDash>
                </Cell>

                <Cell>
                  <ConditionalDash condition={Boolean(performance.enabledBy?.name)}>
                    <Link to={`${performance.enabledBy?.slug}/tracks`}>
                      {performance.enabledBy?.name}
                    </Link>
                  </ConditionalDash>
                </Cell>

                <Cell width={155}>
                  <Flex justify="flex-end">
                    <TrackScheduleButton
                      data-testid={`DiscoveryMode-Track-Button-${track.id}`}
                      isDisabled={isDisabled}
                      trackCampaignData={rowData}
                      onPress={() => {
                        if (!performance.approved) {
                          setInviteModalTrackIndex(index);
                          return;
                        }
                        toggleTrack({
                          campaignId: campaign.id,
                          trackId: track.id,
                          enabled: performance.enabled,
                          ...(isArtist
                            ? { artistId: selectedEntity.id }
                            : { labelId: selectedEntity?.id })
                        });
                      }}
                    />
                  </Flex>
                </Cell>
              </Row>
            );
          })}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const MobileEligibleTracksTable = ({
  tracklist,
  isDisabled,
  setInviteModalTrackIndex
}: EligibleTracksTableProps) => {
  const [actionMenuTrack, setActionMenuTrackIndex] =
    useState<DiscoveryModeTracksFieldsFragment | null>(null);

  return (
    <TableWrapper $isDisabled={isDisabled}>
      {actionMenuTrack && (
        <SchedulingActionMenu
          isOpen={Boolean(actionMenuTrack)}
          onClose={() => setActionMenuTrackIndex(null)}
          trackData={actionMenuTrack}
        />
      )}
      <Table headerProps={{ isHidden: true }}>
        <TableHeader>
          <Column>{''}</Column>
          <Column>{''}</Column>
        </TableHeader>
        <TableBody showLines={false}>
          {tracklist?.map((rowData, index) => {
            const trackAction = rowData.approved
              ? () => setActionMenuTrackIndex(tracklist[index])
              : () => setInviteModalTrackIndex(index);
            return (
              <Row key={index + 1} data-testid="row" onClick={trackAction}>
                <Cell width={'100%'}>
                  <div style={{ padding: '0.5rem' }}>
                    <MobileTrackCellData trackCampaignData={rowData} />
                  </div>
                </Cell>
                <Cell>
                  <Flex justify="flex-end" align="center">
                    {rowData.enabled ? (
                      <Icon
                        type={Icon.TYPES.CHECK_MARK}
                        color={COLORS.SUCCESS.MEDIUM}
                        width={15}
                      />
                    ) : rowData.approved ? (
                      <Icon
                        type={Icon.TYPES.LIGHTNING_BOLT}
                        color={COLORS.SUCCESS.MEDIUM}
                        width={15}
                      />
                    ) : (
                      <Icon
                        type={Icon.TYPES.LOCK}
                        color={COLORS.WARNING.LIGHT}
                        width={15}
                      />
                    )}
                    <EllipsisButton />
                  </Flex>
                </Cell>
              </Row>
            );
          })}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const EligibleTracksTable = ({
  isDisabled,
  isMobile
}: {
  isDisabled: boolean;
  isMobile: boolean;
}) => {
  const { eligibleTracklist, discoveryMode } = useDiscoveryMode();
  const tracklist = [...eligibleTracklist].reverse(); //TODO: this needs to be done on the api
  const [inviteModalTrackIndex, setInviteModalTrackIndex] = useState<
    number | undefined
  >();
  const isModalOpen = typeof inviteModalTrackIndex === 'number';

  if (!tracklist || tracklist?.length === 0) {
    return (
      <div data-testid="DiscoveryMode-Eligible-Table">
        <TablePlaceholder>
          No tracks eligible for {discoveryMode?.upcomingCampaign.title}
        </TablePlaceholder>
      </div>
    );
  }

  const tableProps: EligibleTracksTableProps = {
    setInviteModalTrackIndex,
    tracklist,
    isDisabled,
    campaign: {
      title: discoveryMode?.upcomingCampaign?.title || '',
      id: discoveryMode?.upcomingCampaign?.id || ''
    }
  };

  return (
    <>
      <InviteModal
        isOpen={isModalOpen}
        onClose={() => setInviteModalTrackIndex(undefined)}
        eligibleTrackData={isModalOpen ? tracklist[inviteModalTrackIndex] : undefined}
      />
      {!isMobile ? (
        <DesktopEligibleTracksTable {...tableProps} />
      ) : (
        <MobileEligibleTracksTable {...tableProps} />
      )}
    </>
  );
};

const EligibleTracks = ({
  countdownTimer,
  isDisabled,
  isMobile
}: {
  countdownTimer: React.ReactNode;
  isDisabled: boolean;
  isMobile: boolean;
}) => {
  if (countdownTimer) return countdownTimer;

  return (
    <EligibleTracksTable
      data-testid="DiscoveryMode-Eligible-Table"
      isDisabled={isDisabled}
      isMobile={isMobile}
    />
  );
};

export default EligibleTracks;
