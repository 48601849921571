import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@protonradio/proton-ui';
import Modal from 'components/Modal';
import TextHeader from 'components/TextHeader';

import { BREAKPOINTS } from 'config/constants';
import { ToggleDiscoveryModeMutation } from 'graphql/mutations/discoveryMode';
import useMutationWithAlert from 'hooks/graphql/useMutationWithAlert';

import { OptInProps } from './OptInModal';
import { StyledModal, StyledLink } from '../styles';
import { DiscoveryModeHistoryState } from '../types';
import { mapDiscoveryModeEntities } from 'routes/DiscoveryMode/helpers';
import { EntitySelectionItem } from './EntitySelectionItem';

const OptInSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-height: 320px;
  overflow-y: auto;

  @media (max-width: ${BREAKPOINTS.MEDIUM}px) {
    max-height: 420px;
  }
`;

type SelectedEntity = {
  id: string;
  name: string;
  isSelected: boolean;
  type: 'artist' | 'label';
};

export const SelectEntities = ({ viewer, modalLocation }: OptInProps) => {
  const history = useHistory<DiscoveryModeHistoryState>();
  const [selectedEntities, setSelectedEntities] = useState<SelectedEntity[]>([]);
  const [{ fetching }, executeMutation] = useMutationWithAlert(
    ToggleDiscoveryModeMutation
  );

  const entities = mapDiscoveryModeEntities(viewer);
  const artists = entities.filter(entity => entity.type === 'artist');
  const labels = entities.filter(entity => entity.type === 'label');

  const optInEntities = async () => {
    const results = await Promise.all(
      selectedEntities.map(selectedEntity => {
        const isEntityArtist = selectedEntity.type === 'artist';

        return executeMutation({
          ...(isEntityArtist
            ? { artistId: selectedEntity.id }
            : { labelId: selectedEntity.id }),
          enabled: true
        });
      })
    );

    if (results.some(result => result.hasErrors)) {
      return;
    }

    history.replace({
      pathname: '/dm-opt-in/success',
      search: history.location.search,
      state: { modal: true, modalLocation, step: 'success' }
    });
  };

  const handleSelectionChange = (
    id: string,
    isChecked: boolean,
    type: 'artist' | 'label',
    name: string
  ) => {
    setSelectedEntities(prev => {
      if (isChecked) {
        return [
          ...prev,
          {
            id,
            name,
            isSelected: true,
            type
          }
        ];
      }
      return prev.filter(selected => selected.id !== id);
    });
  };

  return (
    <Modal
      testId="DiscoveryMode-EntitySelection-Modal"
      onClose={() =>
        history.replace({ pathname: modalLocation, search: history.location.search })
      }
      title="Opt In Selection"
    >
      <StyledModal>
        <form
          style={{ width: '95%' }}
          onSubmit={e => {
            e.preventDefault();
            optInEntities();
          }}
        >
          <OptInSelectionContainer
            role="listbox"
            aria-label="Opt In Selection"
            aria-orientation="vertical"
          >
            <TextHeader type={TextHeader.TYPES.XSMALL}>Artists</TextHeader>
            {artists.map(artist => {
              const isPreviouslyOptedIn = artist.isOptedIn;
              const isSelected = selectedEntities.some(
                selected => selected.id === artist.id
              );

              return (
                <EntitySelectionItem
                  key={artist.id}
                  id={artist.id}
                  name={artist.name}
                  imageUrl={artist.imageUrl}
                  type="artist"
                  isSelected={isSelected}
                  isPreviouslyOptedIn={isPreviouslyOptedIn}
                  onSelectionChange={(id, isChecked) =>
                    handleSelectionChange(id, isChecked, 'artist', artist.name)
                  }
                />
              );
            })}

            <br />

            <TextHeader type={TextHeader.TYPES.XSMALL}>Labels</TextHeader>
            {labels.map(label => {
              const isPreviouslyOptedIn = label.isOptedIn;
              const isSelected = selectedEntities.some(
                selected => selected.id === label.id
              );

              return (
                <EntitySelectionItem
                  key={label.id}
                  id={label.id}
                  name={label.name}
                  imageUrl={label.imageUrl}
                  type="label"
                  isSelected={isSelected}
                  isPreviouslyOptedIn={isPreviouslyOptedIn}
                  onSelectionChange={(id, isChecked) =>
                    handleSelectionChange(id, isChecked, 'label', label.name)
                  }
                />
              );
            })}
          </OptInSelectionContainer>

          <Modal.ButtonGroup justify="center">
            <Button variant="secondary">
              <StyledLink
                to={{
                  pathname: '/dm-opt-in',
                  search: history.location.search,
                  state: {
                    modal: true,
                    modalLocation,
                    step: 'dm-opt-in'
                  }
                }}
              >
                Back
              </StyledLink>
            </Button>
            <Button
              type="submit"
              variant="primary"
              onPress={() => optInEntities()}
              isDisabled={selectedEntities.length === 0 || fetching}
            >
              {fetching ? `Opting in...` : 'Opt in'}
            </Button>
          </Modal.ButtonGroup>
        </form>
      </StyledModal>
    </Modal>
  );
};
