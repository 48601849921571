import PropTypes from 'prop-types';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { isProUser } from 'helpers';

import { endImpersonateUser as endImpersonateUserAction } from 'redux/actions/user';
import { isImpersonatingUser } from 'redux/selectors/user';
import { selectHasPromoInboxAccess } from 'redux/selectors/promo';

import useBreakpoint from 'hooks/useBreakpoint';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import UserNavProfiles from './UserNavProfiles';

export const UserNavMenu = ({
  user,
  toggleUserMenu,
  endImpersonateUser,
  impersonate,
  hasPromoInboxAccess
}) => {
  const history = useHistory();

  const isSideNavUsed = useBreakpoint(
    useBreakpoint.BREAKPOINTS.LARGE,
    useBreakpoint.DIRECTIONS.DOWN
  );
  useLockBodyScroll(isSideNavUsed);

  return (
    <li className="nav__dropdown" data-testid="nav-dropdown">
      <div className="Nav__section UserNav__user hidden-md hidden-lg mt-3">
        <div className="UserNav__user__logged-in">You Are Logged In As</div>
        <div className="UserNav__user__username">{user.username}</div>
      </div>

      <UserNavProfiles
        profileType={UserNavProfiles.TYPES.ARTIST}
        user={user}
        toggleUserMenu={toggleUserMenu}
      />
      <UserNavProfiles
        profileType={UserNavProfiles.TYPES.LABEL}
        user={user}
        toggleUserMenu={toggleUserMenu}
      />

      <ul>
        {impersonate && (
          <li
            onClick={() =>
              endImpersonateUser(user.user_id).then(() => {
                toggleUserMenu();
                history.push('/search');
              })
            }
          >
            <div className="nav__list__item UserNav__end_masquerade">
              <span className="i i-user" aria-hidden="true" />
              End Masquerade
            </div>
          </li>
        )}

        {/* <li>
          <Link onClick={toggleUserMenu} to="/discovery-mode" className="nav__list__item">
            <span className="i i-spotify" aria-hidden="true" />
            Discovery Mode
          </Link>
        </li> */}

        {isProUser(user) && (
          <li>
            <Link onClick={toggleUserMenu} to="/track-stack" className="nav__list__item">
              <span className="i i-inbox" aria-hidden="true" />
              Track Stack
            </Link>
          </li>
        )}

        {hasPromoInboxAccess && isProUser(user) && (
          <li>
            <Link
              onClick={toggleUserMenu}
              to="/inbox"
              className="nav__list__item"
              data-testid={UserNavMenu.TEST_IDS.INBOX_LINK}
            >
              <span className="i i-inbox" aria-hidden="true" />
              Inbox
            </Link>
          </li>
        )}

        <li>
          <Link onClick={toggleUserMenu} to="/account" className="nav__list__item">
            <span className="i i-user" aria-hidden="true" />
            Account
          </Link>
        </li>
        <li>
          <Link
            onClick={toggleUserMenu}
            to="/logout"
            className="nav__list__item"
            data-testid={UserNavMenu.TEST_IDS.SIGN_OUT_BUTTON}
          >
            <span className="i i-sign-out" aria-hidden="true" />
            Sign Out
          </Link>
        </li>
      </ul>
    </li>
  );
};

UserNavMenu.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    profiles: PropTypes.array
  }),
  toggleUserMenu: PropTypes.func,
  // redux connect
  impersonate: PropTypes.bool,
  hasPromoInboxAccess: PropTypes.bool,
  endImpersonateUser: PropTypes.func
};

UserNavMenu.TEST_IDS = {
  SIGN_OUT_BUTTON: 'UserNavMenu-sign-out-button',
  INBOX_LINK: 'UserNavMenu-inbox-link'
};

export default connect(
  state => ({
    impersonate: isImpersonatingUser(state),
    hasPromoInboxAccess: selectHasPromoInboxAccess(state)
  }),
  {
    endImpersonateUser: endImpersonateUserAction
  }
)(UserNavMenu);
