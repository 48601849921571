import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextHeader from 'components/TextHeader';
import Button from 'components/Button';
import chevronLeftIcon from 'images/icon-left-chevron-neutral.svg';

import { COLORS, LARGE_BREAK_POINT } from 'config/constants';
import history from 'config/history';
import { generateSelectorsFromTestIds } from 'helpers/utilities';

const StyledHeader = styled.div`
  @media screen and (max-width: ${LARGE_BREAK_POINT}px) {
    background: ${COLORS.GRAY_SUPER_LIGHT};
    border-bottom: 1px solid ${COLORS.BORDER};
  }

  padding-top: 1.5rem;
`;

/**
 * [WizardLayout]
 *
 * @param {func} [renderContent]
 * @param {func} [renderSide]
 * @param {node} [children]
 * @param {string} [title]
 * @param {string} [subtitle]
 * @param {Object} [backButton]
 * @param {Object} backButton.text
 * @param {Object} backButton.onClick
 *
 */

const WizardLayout = ({ renderContent, renderSide, children, ...rest }) => {
  return (
    <div className="container-fluid mb-5">
      <div className="row">
        {renderSide && (
          <Fragment>
            <StyledHeader>
              <div className="col-md-4">
                <HeaderContent {...rest} />
                {renderSide()}
              </div>
            </StyledHeader>

            <div className="col-md-8">
              <div className="mt-3">{renderContent()}</div>
            </div>
          </Fragment>
        )}

        {!renderSide && (
          <div className="col-xs-12 mt-2">
            <HeaderContent {...rest} />
            {children || renderContent()}
          </div>
        )}
      </div>
    </div>
  );
};

const HeaderContent = ({ title, subtitle, backButton }) => {
  const { onClick = () => history.goBack(), text = 'Back' } = backButton || {};

  return (
    <>
      {backButton && (
        <Button
          color={Button.COLORS.NEUTRAL}
          size={Button.SIZES.SMALL}
          onClick={onClick}
          rounded
          className="mb-3"
          prepend={chevronLeftIcon}
          alt="Left Chevron"
          data-testid={WizardLayout.TEST_IDS.BACK}
          text={text}
        />
      )}

      <TextHeader
        data-testid={WizardLayout.TEST_IDS.TITLE}
        type={TextHeader.TYPES.LARGE}
        weight={TextHeader.WEIGHTS.HEAVY}
        customTag="h1"
        className="mb-1"
      >
        {title}
      </TextHeader>

      {subtitle && (
        <TextHeader
          data-testid={WizardLayout.TEST_IDS.SUBTITLE}
          type={TextHeader.TYPES.MEDIUM}
          weight={TextHeader.WEIGHTS.LIGHT}
          customTag="h2"
          className="mb-0 mt-0"
        >
          {subtitle}
        </TextHeader>
      )}
    </>
  );
};

HeaderContent.propTypes = {
  backButton: PropTypes.oneOfType([
    PropTypes.bool, // set to false to omit
    PropTypes.shape({
      onClick: PropTypes.func,
      text: PropTypes.string
    })
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string
};

WizardLayout.TEST_IDS = {
  BACK: 'Wizard-back-btn',
  TITLE: 'Wizard-title',
  SUBTITLE: 'Wizard-subtitle'
};

WizardLayout.SELECTORS = generateSelectorsFromTestIds(WizardLayout.TEST_IDS);

WizardLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.array]),
  renderContent: PropTypes.func,
  renderSide: PropTypes.func
};

export default WizardLayout;
