import React from 'react';

import { Badge, COLORS } from '@protonradio/proton-ui';
import Avatar from 'components/Avatar';
import Modal from 'components/Modal';
import InviteTrackTile from './InviteTrackTile';
import CopyToClipboardTextInput from 'components/Form/Inputs/CopyToClipboardTextInput';

import useUrlQueryParams from 'hooks/useUrlQueryParams';
import { discoveryModeQueryParams } from 'routes/DiscoveryMode/DiscoveryModeContext';
import styled from 'styled-components';
import { mapDiscoveryModeTrackEntities } from 'routes/DiscoveryMode/helpers';

import type { DiscoveryModeTracksFieldsFragment } from 'gql/graphql';

const ArtistList = styled.div`
  border: 1px solid ${COLORS.GRAYSCALE.BORDER};
  border-radius: 0.4rem;
  padding: 0;
  margin: 2rem auto;
  max-height: 250px;
  overflow-y: auto;
`;

const ArtistRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.8rem;
  border-bottom: 1px solid #eee;
  height: 5.5rem;
  &:last-child {
    border-bottom: none;
  }
`;

const ArtistName = styled.span`
  font-size: 16px;
  margin-left: 1rem;
  color: ${COLORS.GRAYSCALE.GRAY_SUPER_DARK};
`;

interface InviteModalProps {
  isOpen: boolean;
  onClose: () => void;
  eligibleTrackData?: DiscoveryModeTracksFieldsFragment;
}

const InviteModal = ({ isOpen, onClose, eligibleTrackData }: InviteModalProps) => {
  const { e: inviter } = useUrlQueryParams(discoveryModeQueryParams);
  if (!isOpen || !eligibleTrackData) return null;

  const track = eligibleTrackData.track;
  const allEntities = mapDiscoveryModeTrackEntities(track, eligibleTrackData.entities);
  const optedInEntities = allEntities.filter(entity => entity.isOptedIn);
  const remainingOptIns = allEntities.length - optedInEntities.length;

  return (
    <Modal testId="DiscoveryMode-Invite-Modal" title="Opt Ins Required" onClose={onClose}>
      <InviteTrackTile track={track} remainingOptIns={remainingOptIns} />

      <br />

      <p>
        All original, featured and remix artists must be opted in before you can enable
        this track.
      </p>

      <ArtistList>
        {allEntities.map(entity => (
          <ArtistRow key={entity.id}>
            <a
              target="_blank"
              href={entity.slug}
              style={{
                color: COLORS.GRAYSCALE.GRAY_DARK,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Avatar
                circle
                imageUrl={entity.imageUrl}
                altTag={entity.name || ''}
                title={entity.name || ''}
              />
              <ArtistName>{entity.name}</ArtistName>
            </a>

            <div style={{ marginLeft: 'auto' }}>
              {entity.isOptedIn ? (
                <Badge variant="success">Opted In</Badge>
              ) : (
                <Badge variant="warning">Not Opted In</Badge>
              )}
            </div>
          </ArtistRow>
        ))}
      </ArtistList>

      <p
        style={{
          backgroundColor: COLORS.GRAYSCALE.GRAY_SUPER_LIGHT,
          padding: '1rem',
          borderRadius: 2
        }}
      >
        To enable this track, share the mobile friendly URL below with your collaborators
        so they can opt into Discovery Mode! Proton is working on an upgrade to help this
        go faster/easier.
      </p>

      <CopyToClipboardTextInput
        content="Copy Invite Link"
        testId="DiscoveryMode-InviteModal-CopyLink"
        className="mt-3"
        text={`https://protonradio.com/discovery-mode?i=${inviter}`}
      />
    </Modal>
  );
};

export default InviteModal;
