import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Pace from 'pace';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { toggleOverlay as toggleOverlayAction } from 'redux/actions/ui';
import { toggleSearchResultsDisplay as toggleSearchResultsDisplayAction } from 'redux/actions/search';
import { isImpersonatingUser } from 'redux/selectors/user';
import { isProUser } from 'helpers/user';
import { generateSelectorsFromTestIds } from 'helpers/utilities';
import { isMobile, isMediumScreen } from 'helpers/screen';
import Screen from 'components/Screen';
import { paceOptions } from 'config/pace';
import UserNav from 'components/UserNav';
import AppNav from 'components/App/AppNav';
import UniversalSearchInput from 'components/UniversalSearchInput';
import logo from 'images/proton-logo.svg';
import { hideIntercomLauncher, showIntercomLauncher } from 'config/intercom';
import { styled } from 'styled-components';
import { Z_INDEX, MAX_WIDTH } from 'config/constants';

const StyledHeader = styled.header`
  z-index: ${Z_INDEX.HEADER};
`;

export class AppHeader extends Component {
  constructor(props) {
    super(props);

    this._toggleUserMenu = this._toggleUserMenu.bind(this);
    this._handleWindowResize = this._handleWindowResize.bind(this);
    this._handleClickOutside = this._handleClickOutside.bind(this);

    this.state = {
      userMenuIsVisible: false
    };

    this.props.history.listen(() => {
      this.setState({
        userMenuIsVisible: false
      });
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this._handleWindowResize);
    document.addEventListener('mousedown', this._handleClickOutside);
    Pace.start(paceOptions);

    // verify overlay properly set on render (handles page refresh)
    this._handleWindowResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleWindowResize);
    document.removeEventListener('mousedown', this._handleClickOutside);
  }

  // if resizing window out of mobile view, remove no-scroll class on body (see DOMStalker)
  _handleWindowResize() {
    const { toggleOverlay, overlayPresent, displayResults } = this.props;

    if (!isMobile() && displayResults && overlayPresent) {
      // remove overlay scroll lock if resizing to desktop and search results shown
      toggleOverlay(false);
    } else if (isMobile() && !overlayPresent && displayResults) {
      // enable overlay scroll lock if resizing to mobile
      toggleOverlay(true);
    }
  }

  _toggleUserMenu() {
    this.setState(
      {
        userMenuIsVisible: !this.state.userMenuIsVisible
      },
      () => {
        if (
          isMediumScreen() &&
          isProUser(this.props.user) &&
          this.state.userMenuIsVisible
        ) {
          hideIntercomLauncher();
        } else {
          //short timeout to wait for close animation to finish
          setTimeout(() => showIntercomLauncher(), 300);
        }
      }
    );
  }

  _handleClickOutside(event) {
    const { displayResults, toggleResultsDisplay, mobileSearchVisible } = this.props;
    if (displayResults) {
      const withinSearchArea1 =
        this.searchArea1 && this.searchArea1.contains(event.target);
      const withinSearchArea2 =
        this.searchArea2 && this.searchArea2.contains(event.target);
      // Mobile search takes up entire screen.  If an action menu is toggled by the search result,
      // clicking on the menu was causing search results to hide
      if (mobileSearchVisible && isMobile()) return;

      if (!withinSearchArea1 && !withinSearchArea2) {
        toggleResultsDisplay(false);
      }
    }
  }

  render() {
    const { userMenuIsVisible } = this.state;
    const { mobileSearchVisible, toggleResultsDisplay, impersonate } = this.props;
    const stagingOrDevelopmentEnv = process.env.REACT_APP_ENV !== 'production';

    const menuStatus = userMenuIsVisible ? 'menu-open' : '';
    const searchStatus = mobileSearchVisible ? 'show-mobile-search' : '';
    const headerClass = classNames(['Header', menuStatus, searchStatus], {
      'Header--warning': impersonate,
      'Header--safety': stagingOrDevelopmentEnv
    });

    return (
      <Fragment>
        <StyledHeader className={headerClass}>
          <div className="Header__overlay" />

          <div className="Header__mobile_search">
            <div
              className="Header__mobile_search--trigger"
              role="button"
              tabIndex="0"
              onClick={() => {
                toggleResultsDisplay(true);
                const field = document.querySelector('input[name="search"][id="mobile"]');
                if (field && field.focus) field.focus();
              }}
            />
          </div>

          <div className="Header__logo">
            <Link to="/" className="js__logo" data-testid={AppHeader.TEST_IDS.LOGO}>
              <img src={logo} alt="Proton Logo" height="34" />
            </Link>
          </div>

          <div className="Header__nav">
            <div className="Header__nav__left">
              <AppNav />
            </div>

            {/* medium to large screen search input */}
            <Screen.LARGE up>
              <div
                className="Header__nav__search"
                ref={node => {
                  this.searchArea1 = node;
                }}
              >
                <UniversalSearchInput toggleSearchResultsDisplay={toggleResultsDisplay} />
              </div>
            </Screen.LARGE>

            <div className="Header__nav__right">
              <UserNav
                userMenuIsVisible={userMenuIsVisible}
                toggleUserMenu={this._toggleUserMenu}
              />
            </div>
          </div>

          {/* x-small to medium screen search input */}
          <Screen.LARGE down>
            <div
              className="Header__nav__search"
              ref={node => {
                this.searchArea2 = node;
              }}
            >
              <div className="search__mobile">
                <UniversalSearchInput
                  toggleSearchResultsDisplay={toggleResultsDisplay}
                  mobile
                />
                <div
                  className="search__mobile__cancel"
                  tabIndex="0"
                  role="button"
                  onClick={() => toggleResultsDisplay(false)}
                >
                  Cancel
                </div>
              </div>
            </div>
          </Screen.LARGE>

          <div className="Header__menu_icon">
            <div
              onClick={this._toggleUserMenu}
              role="button"
              tabIndex="0"
              className="menu__trigger hidden-md hidden-lg"
              data-testid={AppHeader.TEST_IDS.MENU_TRIGGER}
            />
          </div>

          <div
            onClick={this._toggleUserMenu}
            role="button"
            tabIndex="0"
            className="menu__background"
          />
          <div className="pace js__pace" />
        </StyledHeader>
      </Fragment>
    );
  }
}

AppHeader.propTypes = {
  // withRouter
  history: PropTypes.shape({
    listen: PropTypes.func
  }),

  // redux connect (actions)
  toggleOverlay: PropTypes.func,
  toggleResultsDisplay: PropTypes.func,

  // redux connect (store props)
  impersonate: PropTypes.bool,
  overlayPresent: PropTypes.bool,
  displayResults: PropTypes.bool,
  mobileSearchVisible: PropTypes.bool
};

AppHeader.TEST_IDS = {
  MENU_TRIGGER: 'AppHeader-menu-trigger',
  LOGO: 'AppHeader-logo'
};

AppHeader.SELECTORS = generateSelectorsFromTestIds(AppHeader.TEST_IDS);

export default withRouter(
  connect(
    state => ({
      user: state.user,
      impersonate: isImpersonatingUser(state),
      overlayPresent: state.ui.overlayPresent,
      displayResults: state.search.displayResults,
      mobileSearchVisible: state.search.displayMobileInput
    }),
    {
      toggleOverlay: toggleOverlayAction,
      toggleResultsDisplay: toggleSearchResultsDisplayAction
    }
  )(AppHeader)
);
