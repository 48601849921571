import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/Modal';
import TextHeader from 'components/TextHeader';
import { TextEmphasis, Button } from '@protonradio/proton-ui';

import { ToggleDiscoveryModeMutation } from 'graphql/mutations/discoveryMode';
import useMutationWithAlert from 'hooks/graphql/useMutationWithAlert';

import { intercomPaths } from 'routes/DiscoveryMode/DiscoveryMode';
import { OptInProps } from './OptInModal';
import { StyledTooltipContent, StyledTextHeader, StyledExternalLink } from '../styles';
import { DiscoveryModeHistoryState } from '../types';

export const OnboardingStep = ({ modalLocation, entityId, entityType }: OptInProps) => {
  const history = useHistory<DiscoveryModeHistoryState>();
  const [{ fetching }, executeMutation] = useMutationWithAlert(
    ToggleDiscoveryModeMutation
  );

  const handleContinue = useCallback(async () => {
    if (entityId) {
      try {
        const isEntityArtist = entityType === 'artist';
        const result = await executeMutation({
          ...(isEntityArtist ? { artistId: entityId } : { labelId: entityId }),
          enabled: true
        });

        if (result.hasErrors) throw new Error('Something went wrong');

        history.replace({
          pathname: `/dm-opt-in/success`,
          search: history.location.search,
          state: { modal: true, modalLocation, entityId, step: 'success' }
        });
      } catch (error) {
        history.push(modalLocation);
      }
    } else {
      history.replace({
        pathname: '/dm-opt-in/select',
        search: history.location.search,
        state: { modal: true, modalLocation, step: 'select' }
      });
    }
  }, [executeMutation, entityId, modalLocation, history]);

  return (
    <Modal
      testId="DiscoveryMode-Onboarding-Modal"
      onClose={() =>
        history.replace({ pathname: modalLocation, search: history.location.search })
      }
      title="Opt In to Discovery Mode?"
    >
      <>
        <p>
          By opting in, you and your&nbsp;
          <TextEmphasis
            tooltipProps={{
              arrow: true,
              content: (
                <StyledTooltipContent>
                  Any other original artists, featured artists, or remixers on eligible
                  tracks.
                </StyledTooltipContent>
              )
            }}
          >
            collaborators
          </TextEmphasis>
          &nbsp;can add&nbsp;
          <TextEmphasis
            tooltipProps={{
              arrow: true,
              content: (
                <StyledTooltipContent>
                  A track is eligible when it has been out for at least 1 month, it has at
                  least one play in Spotify Radio or Autoplay in the last month, and all
                  the tracks collaborators have opted in.
                </StyledTooltipContent>
              )
            }}
          >
            eligible tracks
          </TextEmphasis>
          &nbsp; to monthly Discovery Mode campaigns. If a collaborator hasn&#39;t opted
          in yet, we&#39;ll help you invite them.
        </p>
      </>

      <div className="mt-1" />

      <p>
        While opted in, collaborators can activate Discovery Mode on eligible tracks
        featuring both of you as artists (original, featured, or remix) without needing
        your additional approval.
      </p>

      <div className="mt-2" />

      <StyledTextHeader type={TextHeader.TYPES.MEDIUM}>
        Discovery Mode Costs
      </StyledTextHeader>
      <p>
        Spotify deducts 30% of earnings from plays on{' '}
        <TextEmphasis
          tooltipProps={{
            arrow: true,
            content: (
              <StyledTooltipContent>
                Spotify Radio, Autoplay, Daily Mixes, Artist Mixes, Mood Mixes, Decade
                Mixes, and Genre Mixes
              </StyledTooltipContent>
            )
          }}
        >
          participating playlists.
        </TextEmphasis>{' '}
        These deductions are shared between Proton, the label, and the artists based on
        royalty splits.
      </p>

      <div className="mt-1" />

      <p>Change your mind? You can opt out at any time.</p>

      <div className="mt-1" />

      <Modal.ButtonGroup justify="center">
        <Button variant="secondary" isDisabled={fetching}>
          <StyledExternalLink
            href={intercomPaths.faq}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More
          </StyledExternalLink>
        </Button>

        <Button
          onPress={() => handleContinue()}
          isDisabled={fetching}
          data-testid="OptIn-Button"
        >
          {fetching ? 'Opting in...' : 'Continue'}
        </Button>
      </Modal.ButtonGroup>
    </Modal>
  );
};
