import React from 'react';
import Flex from 'components/Flex';
import { RemoveButton, CartItem, CartImageContainer, CartImage } from './styles';
import { useDiscoveryMode } from '../DiscoveryModeContext';
import { Tombstone } from '@protonradio/proton-ui';
import { isEntityArtist } from '../helpers';
import { ScheduledTrack, ScheduledTrackLoading } from './useScheduledQueue';
import { isTrackLoaded } from './helpers';

const RemoveableImage = ({
  track,
  onRemove,
}: {
  track: ScheduledTrack | null;
  onRemove: () => void;
}) => {
  if (!track) return null;
  if (track.loading === 'adding') return <Tombstone borderRadius="8px" height="100%" />;

  const title = isTrackLoaded(track) ? `${track.title} by ${track.artist}` : '';
  const img = isTrackLoaded(track) ? track.img : '';

  return (
    <CartImageContainer>
      <CartImage
        src={img}
        alt={title}
        title={title}
        $isRemoving={track.loading === 'removing'}
      />
      <RemoveButton id="remove-button" onClick={onRemove}>
        &#x2715;
      </RemoveButton>
    </CartImageContainer>
  );
};

const SchedulingCart = () => {
  const {
    discoveryMode,
    scheduledQueue: { queue, toggleTrack },
    initialLoading,
    selectedEntity,
  } = useDiscoveryMode();

  return (
    <>
      <Flex
        justify="center"
        className="mt-1 mb-1"
        data-testid="DiscoveryMode-SchedulingCart"
      >
        {[...queue, ...Array(Math.max(0, 3 - queue.length))].map(
          (track: ScheduledTrack, index: number) => {
            // If loading, pass in ScheduledTrackLoading objects
            const trackItem: ScheduledTrackLoading = initialLoading
              ? {
                  id: `loading-${index}`,
                  error: null,
                  loading: 'adding' as const
                }
              : queue[index];

            return (
              <CartItem key={`item-${index}`}>
                <RemoveableImage
                  track={trackItem}
                  onRemove={() => {
                    if (!discoveryMode) return;

                    const isArtist = isEntityArtist(selectedEntity);

                    toggleTrack({
                      trackId: track.id,
                      campaignId: discoveryMode?.upcomingCampaign?.id,
                      enabled: true,
                      ...(isArtist
                        ? { artistId: selectedEntity.id }
                        : { labelId: selectedEntity?.id })
                    });
                  }}
                />
              </CartItem>
            );
          }
        )}
      </Flex>
    </>
  );
};

export default SchedulingCart;
