import { createAction } from '@reduxjs/toolkit';
import ahoy from 'ahoy.js';
import _ from 'lodash';

import * as api from 'api';
import { cancelPendingRequests } from 'config/axios/cancelRequests';
import { ASYNC_OPERATIONS } from 'config/constants';
import { en } from 'config/constants/localization';
import { player } from 'hooks/useProtonPlayer';
import { AccountNotificationType, V2User, V2UserSetting } from 'types';

import type { BasicUserInfo, ChangePasswordInput, ResetPasswordInput } from 'api';
import { AppDispatch, store } from 'config/store';
import {
  clearAuthCookies,
  clearMasqueradeCookies,
  getProtonJwtPayload,
  removeProtonCookie,
  setAuthCookies,
  setMasqueradeCookie
} from 'helpers';
import { resetFeatureFlags } from './features';
import { clearToken, setToken, tokenHandler } from './token';
import { addAlertMessage, showAlert } from './ui';
import { createAsyncActions } from './utilities';

export const setUser = createAction<Partial<V2User>>('USER_SET');
export const replaceUser = createAction<V2User>('USER_REPLACE'); // Action used for masquerade
export const setUserSettings = createAction<V2UserSetting[]>('USER_SETTINGS_SET');
export const fetchingUser = createAction('USER_FETCHING');
export const clearUser = createAction('USER_CLEAR');

type LogoutOptions = {
  persistAuthCookies?: boolean;
};

// dispatches action that reducers watch for and clear there data
export const logoutUser = (options?: LogoutOptions) => (dispatch: AppDispatch) => {
  const { persistAuthCookies } = options || {};

  if (player.mode !== 'RADIO') player.send('pause');

  // before signing the user out, we want to ensure any inflight requests are canceled
  //to prevent unintended data storage from request returning after clearing redux
  cancelPendingRequests();
  // clear value of auth cookie and immediately expire
  if (!persistAuthCookies) clearAuthCookies();
  dispatch(clearUser());
  dispatch(clearToken());
  dispatch(resetFeatureFlags());

  // NOTE: ahoy.reset() was not working as expected. The relevant cookies were not reliably cleared.
  removeProtonCookie('ahoy_visit');
  removeProtonCookie('ahoy_visitor');
  removeProtonCookie('ahoy_events');
  removeProtonCookie('ahoy_track');
  removeProtonCookie('preferred_discovery_mode_entity');

  ahoy.trackView();
};

// ASYNC ACTIONS:

/**
 * fetch current user data and store result in redux.
 */

export const getUser =
  (id: number, silentFetch?: boolean) => async (dispatch: AppDispatch) => {
    if (!silentFetch) dispatch(fetchingUser());

    try {
      const user = await api.getUser(id);
      dispatch(setUser(user));
      return user;
    } catch (error: unknown) {
      dispatch(showAlert({ error, forceMessage: en.account.loadingUserError }));

      if (_.get(error, 'response.status') === 404) {
        // user never returned from db, ensure redux state is clear
        dispatch(logoutUser());
      }
    }
  };

interface UpdateUserOptions {
  displayConfirmation?: boolean;
  onSuccess?: () => void;
  onError?: (e: Error) => void;
}

// update user in server
export const updateUser =
  (user: Partial<V2User>, options?: UpdateUserOptions) =>
  async (dispatch: AppDispatch, getState: typeof store.getState) => {
    const { displayConfirmation, onSuccess, onError } = options || {};

    const user_id = user.user_id ? user.user_id : getState().user.user_id!;

    try {
      await api.updateUser({ ...user, user_id });
      if (displayConfirmation) {
        dispatch(showAlert({ message: en.account.accountUpdateSuccess }));
      }
      dispatch(setUser(user));

      if (onSuccess) onSuccess();
    } catch (e: unknown) {
      if (e instanceof Error && onError) onError(e);
      throw e;
    }
  };

// TODO: Remove this interface when the utilities file where `createAsyncActions` lives is typed
// interface UpdateUserActions {
//   call: ActionCreatorWithPreparedPayload<V2UserSetting[], 'USER_SETTINGS_UPDATE'>;
//   request: ActionCreatorWithPreparedPayload<V2UserSetting[], 'USER_SETTINGS_UPDATE_REQUEST'>;
//   error: ActionCreatorWithPreparedPayload<V2UserSetting[], 'USER_SETTINGS_UPDATE_ERROR'>;
//   success: ActionCreatorWithPreparedPayload<V2UserSetting[], 'USER_SETTINGS_UPDATE_SUCCESS'>;
// }

export const updateUserSettings = createAsyncActions<V2UserSetting>(
  'USER_SETTINGS',
  ASYNC_OPERATIONS.UPDATE
);

// disconnect soundcloud
export const disconnectSoundCloud =
  (user_id: number, auth_id: number) => async (dispatch: AppDispatch) => {
    const response = await api.disconnectSoundCloud(auth_id);
    switch (response.status) {
      case 'ok':
        dispatch(addAlertMessage(en.account.disconnectSoundcloudSuccess));
        return dispatch(getUser(user_id));
      case 'label_connected':
        return dispatch(addAlertMessage(en.account.musicLabelAlreadyConnected));
      case 'artist_connected':
        return dispatch(addAlertMessage(en.account.artistAlreadyConnected));
      default:
        return dispatch(addAlertMessage(en.errors.generic));
    }
  };

// disconnect spotify
export const disconnectSpotify = () => (dispatch: AppDispatch) =>
  api.disconnectSpotify().then(() => {
    dispatch(addAlertMessage(en.account.disconnectSpotifySuccess));

    dispatch(setUser({ spotify_auth: null }));
  });

export const disconnectGoogle = () => async (dispatch: AppDispatch) => {
  await api.disconnectGoogle();
  dispatch(
    addAlertMessage({ message: en.account.disconnectGoogleSuccess, timeout: true })
  );
  dispatch(setUser({ google_oauth: false, google_identity: null }));
};

export const disconnectDropbox = () => async (dispatch: AppDispatch) => {
  const response = await api.disconnectDropbox();
  dispatch(addAlertMessage(en.account.disconnectDropboxSuccess));

  if (response.success) dispatch(setUser({ dropbox_oauth: null }));
};

export const confirmUser =
  (token: string, proton_id: string) => async (dispatch: AppDispatch) => {
    const response = await api.confirmUser({
      token,
      proton_id
    });

    const { jwt, refresh_token } = response.data;

    tokenHandler(dispatch)({ jwt, refresh_token });
    dispatch(setUser(response.data.user));
    dispatch(addAlertMessage(en.account.confirmed));
  };

export const resetPassword =
  (values: ResetPasswordInput) => async (dispatch: AppDispatch) => {
    const response = await api.resetPassword(values);
    const { userId } = tokenHandler(dispatch)(response.data);

    await dispatch(getUser(userId));
    dispatch(addAlertMessage(en.account.resetPasswordSuccess));
    return response;
  };

export const changePassword =
  (values: ChangePasswordInput) => async (dispatch: AppDispatch) => {
    const response = await api.changePassword(values);
    tokenHandler(dispatch)(response.data);
    dispatch(addAlertMessage(en.account.passwordUpdated));

    return response;
  };

// register new user to server
export const registerUser = (user: BasicUserInfo) => async (dispatch: AppDispatch) => {
  await api.registerUser(user);
  dispatch(setUser(user));
};

export const updateUserNotifications =
  (notificationName: AccountNotificationType, value: boolean) =>
  async (dispatch: AppDispatch) => {
    // optimistally set in redux store since state drives controlled components (Switches)
    dispatch(setUser({ [notificationName]: value }));
    try {
      const { data: user } = await api.updateNotifications(notificationName, value);
      // NOTE: don't set user here since optimistically updated above (can result in jumping if multiple requests sent)
      return user;
    } catch (err: unknown) {
      dispatch(addAlertMessage({ message: en.errors.generic, type: 'warning' }));
      // undo optimistic change
      dispatch(setUser({ [notificationName]: !value }));
      throw err;
    }
  };

export const confirmUserDelete =
  (userId: number, deleteToken: string) => async (dispatch: AppDispatch) => {
    const response = await api.confirmAccountDeletion(userId, deleteToken);
    const { data } = response;
    if (data.success) {
      // API returns succsess message: "User queued for deletion"
      dispatch(setUser({ delete_at: data.delete_at }));
    } else {
      throw new Error(data.message);
    }

    return response;
  };

export const cancelAccountDeletion =
  (userId: number) => async (dispatch: AppDispatch) => {
    await api.cancelAccountDeletion(userId);
    // fetch user to get updated user data without "delete_at" attribute
    await dispatch(getUser(userId));
  };

// NOTE: For impersonate/endImpersonate actions, we need to set the token
// in redux state before we can fetch the user (see axios config). So, we
// have to set token and user in two separate steps.

export const impersonateUser = (userId: number) => async (dispatch: AppDispatch) => {
  try {
    const {
      data: { impersonate_jwt, impersonate_refresh_token }
    } = await api.impersonateUser(userId);
    const user = await api.getUser(userId, impersonate_jwt);
    // NOTE: We want to clear any data related to the admins user account in redux and then
    // log them in as the new user
    dispatch(logoutUser({ persistAuthCookies: true }));
    setMasqueradeCookie(impersonate_jwt, impersonate_refresh_token);

    // We need to set the token in redux state before we can fetch the user (see axios config),
    // so we have to set token and user in two separate step.
    dispatch(
      setToken({
        userId,
        jwt: impersonate_jwt,
        refreshToken: impersonate_refresh_token,
        isImpersonating: true
      })
    );

    dispatch(replaceUser(user));
    return { user, jwt: impersonate_jwt, refreshToken: impersonate_refresh_token };
  } catch (e: unknown) {
    if (e instanceof Error) console.error('Error impersonating user:', e.message);
  }
};

export const endImpersonateUser = (userId: number) => async (dispatch: AppDispatch) => {
  try {
    const {
      data: { jwt, refresh_token }
    } = await api.endImpersonateUser(userId);
    const { user_id: adminUserId } = getProtonJwtPayload(jwt);
    if (!adminUserId) throw new Error('Error decoding jwt');

    // We want to clear the redux store of any masqueraded user data before logging
    // the admin user back in
    const user = await api.getUser(adminUserId, jwt);
    dispatch(logoutUser());

    setAuthCookies(jwt, refresh_token);
    clearMasqueradeCookies();
    dispatch(
      setToken({
        userId: adminUserId,
        jwt,
        refreshToken: refresh_token,
        isImpersonating: false
      })
    );

    dispatch(replaceUser(user));
    return { user, jwt };
  } catch (e: unknown) {
    if (e instanceof Error)
      console.error(`Error ending masquerade session for userId ${userId}`, e.message);

    dispatch(logoutUser());
    clearMasqueradeCookies();
    clearAuthCookies();
  }
};
