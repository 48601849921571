import PropTypes from 'prop-types';
import React from 'react';
import { LabelAvatar } from 'components/Avatar';
import { Link } from 'react-router-dom';

const LabelSearchResult = ({ id, image, slug, matched, hideSearchResults }) => (
  <Link to={slug} onClick={hideSearchResults} data-testid={`LabelSearchResult-${id}`}>
    <div className="UniversalSearchResultRow" data-testid={LabelSearchResult.TEST_IDS.ROW}>
      <LabelAvatar imageUrl={image} size="5rem" altTag="Label Art" />

      <div className="UniversalSearchResultRow__info">
        <div>
          <div dangerouslySetInnerHTML={{ __html: matched.name }} />
        </div>
      </div>
    </div>
  </Link>
);

LabelSearchResult.SELECTORS = {
  getContainerSelector: id => `[data-testid=LabelSearchResult-${id}]`
};

LabelSearchResult.TEST_IDS = {
  ROW: 'label-search-result-row'
};

LabelSearchResult.propTypes = {
  // Label props:
  id: PropTypes.number,
  name: PropTypes.string,
  image: PropTypes.string,
  slug: PropTypes.string,
  // universal search provided props:
  matched: PropTypes.shape({
    name: PropTypes.string
  }),
  hideSearchResults: PropTypes.func
};

export default LabelSearchResult;
