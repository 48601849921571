import React from 'react';

import { Banner, Tombstone } from '@protonradio/proton-ui';
import { COLORS } from '@protonradio/proton-ui/constants';
import { CalendarIcon } from 'components/Icons';
import Flex from 'components/Flex';
import SchedulingCart from './SchedulingCart';
import { CalendarBackground, SidebarHeader, SidebarSubheader } from './styles';

import { useDiscoveryMode } from '../DiscoveryModeContext';
import { VerticalLayout } from '../styles';
import { getFormattedSchedulingEndDate } from './helpers';

const SidebarTombstones = () => (
  <>
    <div style={{ padding: '0 0 2rem 0', width: '100%' }}>
      <Tombstone height="43px" />
    </div>

    <Tombstone width="200px" height="18px" />

    <SidebarHeader>
      <Tombstone width="200px" height="28px" />
    </SidebarHeader>

    <div style={{ padding: '2rem 0 1rem 0', width: '100%' }}>
      <Tombstone height="16px" />
    </div>
  </>
);

const SchedulingSidebar = () => {
  const {
    discoveryMode,
    selectedEntity,
    initialLoading,
    scheduledQueue: { count }
  } = useDiscoveryMode();
  const { schedulingEndsAt, title } = discoveryMode?.upcomingCampaign || {};
  const formattedDate = getFormattedSchedulingEndDate(schedulingEndsAt);

  return (
    <VerticalLayout>
      {formattedDate && !initialLoading && (
        <Banner
          variant="danger"
          icon={
            <CalendarBackground>
              <CalendarIcon color={Banner.colors.danger.title} size={14} />
            </CalendarBackground>
          }
        >
          <Banner.Content>Schedule by {formattedDate}</Banner.Content>
        </Banner>
      )}

      <Flex column>
        {initialLoading ? (
          <SidebarTombstones />
        ) : (
          <>
            <SidebarSubheader>Remaining tracks for</SidebarSubheader>
            <SidebarHeader>{title?.split(' ')[0]} Campaign</SidebarHeader>

            <Flex justify="space-between" className="mt-3">
              <SidebarSubheader data-testid="DiscoveryMode-ScheduledTracks-Count">
                <div
                  style={{
                    fontSize: '3.2rem',
                    color: `${COLORS.BRAND.PRIMARY}`,
                    fontWeight: 800,
                    marginRight: '4px'
                  }}
                >
                  {count}
                </div>{' '}
                of 3
              </SidebarSubheader>
              <SidebarSubheader>{selectedEntity?.name}</SidebarSubheader>
            </Flex>
          </>
        )}

        <SchedulingCart />

        {!initialLoading && (
          <p>You can submit up to three tracks for Spotify Discovery Mode per month.</p>
        )}
      </Flex>
    </VerticalLayout>
  );
};

export default SchedulingSidebar;
