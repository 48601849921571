import { COLORS } from '@protonradio/proton-ui/constants';
import { BREAKPOINTS } from 'config/constants';
import styled from 'styled-components';

const DISCOVERY_MODE_MARGIN = '2rem';
export const DISCOVERY_MODE_BREAKPOINT = BREAKPOINTS.MD;

/* Global Components */

export const VerticalLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  margin-bottom: ${DISCOVERY_MODE_MARGIN};
  margin-top: ${DISCOVERY_MODE_MARGIN};

  @media (max-width: ${DISCOVERY_MODE_BREAKPOINT}px) {
    margin-bottom: 0;
    margin-top: 0;
    gap: 0;
  }
`;

export const TablePlaceholder = styled.div`
  background-color: ${COLORS.GRAYSCALE.GRAY_SUPER_LIGHT};
  height: 100%;
  padding: 2rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;

  @media (max-width: ${DISCOVERY_MODE_BREAKPOINT}px) {
    margin: 1rem;
  }
`;

/* Header Components */

export const GradientGreenBackground = styled.div`
  background: ${COLORS.GRAYSCALE.GRAY_SUPER_DARK};
  background-image: linear-gradient(
    270deg,
    rgba(67, 160, 71, 0.43) 1%,
    rgba(0, 0, 0, 0.5) 30%
  );
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
`;

export const LogoTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;

  @media (max-width: ${BREAKPOINTS.LG}px) {
    margin-top: 4rem;
  }

  span {
    margin-left: 1.6rem;
  }

  svg {
    @media (max-width: ${BREAKPOINTS.SM}px) {
      width: 0;
      height: 0;
    }

    @media (min-width: ${BREAKPOINTS.SM}px) and (max-width: ${BREAKPOINTS.MD}px) {
      width: 52px;
      height: 52px;
      margin-right: -0.6rem;
    }

    @media (min-width: ${BREAKPOINTS.MD}px) and (max-width: ${BREAKPOINTS.LG}px) {
      width: 68px;
      height: 68px;
    }

    @media (min-width: ${BREAKPOINTS.LG}px) and (max-width: ${BREAKPOINTS.XL}px) {
      width: 72px;
      height: 72px;
    }

    @media (min-width: ${BREAKPOINTS.XL}px) {
      width: 78px;
      height: 78px;
    }
  }
`;

/* Countdown Components */

export const CountdownTimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
`;

export const TimeLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 8rem;
  background-color: ${COLORS.GRAYSCALE.WHITE};
  border-radius: 1rem;
  border: 0.1rem solid ${COLORS.GRAYSCALE.GRAY_LIGHTEST};

  font-size: 4rem;
  font-weight: 700;
  color: ${COLORS.BRAND.PRIMARY};
  letter-spacing: 0.2rem;
  text-align: center;
  line-height: 1;

  @media (max-width: ${BREAKPOINTS.SM}px) {
    width: 8rem;
    height: 6rem;
    font-size: 3rem;
  }
`;

export const Colon = styled.div`
  margin: 0 1rem;
  font-size: 4rem;
  font-weight: 700;
  color: ${COLORS.BRAND.PRIMARY};

  @media (max-width: ${BREAKPOINTS.SM}px) {
    margin: 0 0.4rem;
    font-size: 3rem;
  }
`;

/* Table Components */

export const TableWrapper = styled.div<{ $isDisabled: boolean }>`
  width: 100%;
  opacity: ${props => (props.$isDisabled ? 0.5 : 1)};
  pointer-events: ${props => (props.$isDisabled ? 'none' : 'auto')};
`;
