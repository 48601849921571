import React, { createContext, useContext, ReactNode } from 'react';
import useScheduledQueue from './Scheduling/useScheduledQueue';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import { useLocation } from 'react-router-dom';
import { useQueryWithAlert } from 'hooks/graphql/useQueryWithAlert';

import {
  DiscoveryModeConfigsQuery,
  DiscoveryModeEligibleTracksQuery,
  DiscoveryModeEnabledTracksQuery,
  DiscoveryModeScheduledTracksQuery,
  DiscoveryModeStateQuery,
  DiscoveryModeTracksFragment
} from 'graphql/queries/discoveryMode';
import {
  DiscoveryModeCampaignStateEnum,
  DiscoveryModeQuery,
  DiscoveryModeTracksFieldsFragment
} from 'gql/graphql';
import { getFragmentData } from 'gql';

import { StringParam } from 'use-query-params';
import { isEntityArtist, mapDiscoveryModeEntities } from './helpers';
import { mapScheduledTrackData } from './Scheduling/helpers';

export const discoveryModeQueryParams = {
  q: { type: StringParam, default: '' }, // search query
  e: { type: StringParam, default: '' }, // selected entity name
  i: { type: StringParam, default: '' } // inviter entity name
};

export type DiscoveryModeEntity = {
  id: string;
  name: string;
  isOptedIn: boolean;
  type: 'artist' | 'label';
  imageUrl: string;
  slug: string;
};

interface DiscoveryModeContextType {
  /* Initial loading state when the page is first loaded (page refresh) */
  initialLoading: boolean;

  /* Loading state for just the track lists (eligibleTracks or enabledTracks) */
  tracksLoading: boolean;

  /* Error fetching DiscoveryMode data */
  error?: Error;

  /* DiscoveryMode data */
  discoveryMode?: DiscoveryModeQuery['discoveryMode'];

  /* Eligible tracks for the upcoming campaign */
  eligibleTracklist: DiscoveryModeTracksFieldsFragment[];

  /* Enabled tracks for the currently running campaign */
  enabledTracklist: DiscoveryModeTracksFieldsFragment[];

  /* Scheduled queue for the current campaign */
  scheduledQueue: ReturnType<typeof useScheduledQueue>;

  /* Entities (artists/labels) that the user has opted in to */
  entities: DiscoveryModeEntity[];

  /* Selected entity (artist/label) */
  selectedEntity?: DiscoveryModeEntity;

  /* User level feature flag for seen onboarding */
  hasSeenOnboarding: boolean;

  /* Whether the current date is before the scheduling date */
  isBeforeScheduling: boolean;

  /* Whether the current date is after the scheduling date */
  isAfterScheduling: boolean;

  /* Whether the current route is the enabled route */
  isEnabledRoute: boolean;
}

const DiscoveryModeContext = createContext<DiscoveryModeContextType | undefined>(
  undefined
);

export const useDiscoveryMode = () => {
  const context = useContext(DiscoveryModeContext);
  if (!context) {
    throw new Error('useDiscoveryMode must be used within a DiscoveryModeProvider');
  }
  return context;
};

export const DiscoveryModeProvider = ({ children }: { children: ReactNode }) => {
  const { pathname } = useLocation();
  const { e: selectedEntity, q: searchQuery } = useUrlQueryParams(
    discoveryModeQueryParams
  );

  // Get viewer data
  const [{ data: viewerData, error: viewerError, fetching: viewerFetching }] =
    useQueryWithAlert({
      query: DiscoveryModeConfigsQuery
    });

  const entities = mapDiscoveryModeEntities(viewerData?.viewer);
  const foundEntity = entities?.find(entity => entity.name === selectedEntity);

  const userHasSeenOnboarding =
    viewerData?.viewer?.configs?.hasSeenDiscoveryModeOnboarding ?? false;

  // Get DiscoveryMode state
  const [{ data, error: dmError }] = useQueryWithAlert({
    query: DiscoveryModeStateQuery,
    pause: !viewerData
  });

  // Get unfiltered scheduled tracks
  const [{ data: scheduledTracksData, error: scheduledTracksError }] = useQueryWithAlert({
    query: DiscoveryModeScheduledTracksQuery,
    pause: !viewerData
  });

  // Get eligible tracks
  const [
    {
      data: eligibleTracksData,
      error: eligibleTracksError,
      fetching: eligibleTracksFetching,
      stale: eligibleTracksStale
    }
  ] = useQueryWithAlert({
    query: DiscoveryModeEligibleTracksQuery,
    variables: {
      filter: {
        ...(isEntityArtist(foundEntity)
          ? { artistNames: { cont: selectedEntity } }
          : { labelName: { cont: selectedEntity } }),
        trackName: { cont: searchQuery }
      }
    },
    pause: !viewerData || !pathname.includes('eligible')
  });

  // Get enabled tracks
  const [
    {
      data: enabledTracksData,
      error: enabledTracksError,
      fetching: enabledTracksFetching,
      stale: enabledTracksStale
    }
  ] = useQueryWithAlert({
    query: DiscoveryModeEnabledTracksQuery,
    pause: !viewerData || !pathname.includes('enabled')
  });

  const discoveryMode = data?.discoveryMode;
  const eligibleTracks =
    eligibleTracksData?.discoveryMode?.upcomingCampaign?.eligibleTracks;
  const eligibleTracklist =
    getFragmentData(DiscoveryModeTracksFragment, eligibleTracks) || [];

  const enabledTracks = enabledTracksData?.discoveryMode?.runningCampaign?.enabledTracks;
  const enabledTracklist =
    getFragmentData(DiscoveryModeTracksFragment, enabledTracks) || [];

  const scheduledTracks =
    scheduledTracksData?.discoveryMode?.upcomingCampaign?.eligibleTracks;
  const scheduledTracklist =
    getFragmentData(DiscoveryModeTracksFragment, scheduledTracks) || [];

  const scheduledQueue = useScheduledQueue(
    mapScheduledTrackData(scheduledTracklist, foundEntity)
  );

  const isBeforeScheduling =
    discoveryMode?.upcomingCampaign?.state ===
    DiscoveryModeCampaignStateEnum.BeforeScheduling;

  const isAfterScheduling =
    discoveryMode?.upcomingCampaign?.state ===
      DiscoveryModeCampaignStateEnum.AfterScheduling ||
    discoveryMode?.upcomingCampaign?.state === DiscoveryModeCampaignStateEnum.Future;

  // Check if tracks are currently being fetched (but we have some initial data)
  const tracksLoading =
    (!eligibleTracksStale || !enabledTracksStale) &&
    (eligibleTracksFetching || enabledTracksFetching) &&
    Boolean(eligibleTracksData || enabledTracksData);

  // Check if we're in the initial loading state (no data yet)
  const initialLoading =
    viewerFetching ||
    ((eligibleTracksFetching || enabledTracksFetching) &&
      (!eligibleTracksData || !enabledTracksData));

  return (
    <DiscoveryModeContext.Provider
      value={{
        initialLoading,
        tracksLoading,
        error:
          dmError ||
          viewerError ||
          eligibleTracksError ||
          enabledTracksError ||
          scheduledTracksError,
        discoveryMode,
        eligibleTracklist,
        enabledTracklist,
        scheduledQueue,
        entities,
        selectedEntity: foundEntity,
        hasSeenOnboarding: userHasSeenOnboarding,
        isBeforeScheduling,
        isAfterScheduling,
        isEnabledRoute: pathname.includes('enabled')
      }}
    >
      {children}
    </DiscoveryModeContext.Provider>
  );
};
